import React from 'react';
import img from '../../../../assets/why.jpg'
export default function Pourquoi() {
    return (
        <div className='w-11/12 flex my-12  gap-10  max-lg:flex-col lg:flex-row justify-around items-center'>

            <img src={img} alt='photos' className='max-md:w-full lg:w-5/12 rounded-[8px]' />

            <div className="flex flex-col">
                <span className="text-[#2C2F24] text-3xl mb-2 font-semibold">
                    Pourquoi choisir NATURA GERME ?
                </span>
                <p className=' flex flex-col gap-y-1'>
                    <span>
                        <b> Personnalisation :</b> Recevez des recommandations de formation adaptées à vos besoins uniques. <br />
                    </span>
                    <span><b>Efficacité : </b> Optimisez votre apprentissage avec des sessions de formation ciblées. <br /></span>
                    <span><b>Soutien expert :</b> Bénéficiez de l’accompagnement de formateurs qualifiés et expérimentés.</span>
                </p>
            </div>
        </div>
    )
}