import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import axios from 'axios';
import { api } from '../../../api'
import Header from '../../components/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import Formation from '../../../entrepreneur/pages/Formation'

const DetailFormation = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    id: '',
    titre: '',
    description: '',
    duree: '',
    dateDebut: '',
    dateFin: '',
    lieu: '',
    photo: '',
    organisation: '',
    user_id: '',
  });

  const [dataFormation, setDataFormation] = useState({
    id: 'id',
    titre: '',
    description: '',
    duree: '',
    dateDebut: '',
    dateFin: '',
    lieu: '',
    photo: '',
    organisation: '',
    user_id: '',
  });

  const [formateurs, setFormateurs] = useState([])
  const [trainers, setTrainers] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {setTitle} = useOutletContext()
  // const [dataFormation, setDataFormation] = useState([])

  const availableTrainers = ['Formateur 1', 'Formateur 2', 'Formateur 3'];

  const getDetailFormation = () => {

      axios.get(api+`/api/getDetailFormation/${id}`)
        .then(response => {
          console.log(response.data[0])
          setDataFormation(response.data[0])
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des formations:", error)
        })
  
  }

  // get all formateurs
  const getAllFormateurs = () => {

    axios.get(api + '/api/getAllFormateur')
    .then(response =>{
      // console.log('Données modifiées')
      // getProfileFormateur();
      setFormateurs(response.data);
      // console.log(availableTrainers);

    })
    .catch(error => {

    })
  }

  useEffect(() => {
    setTitle("Formation")
  
    getDetailFormation()
    getAllFormateurs()
  
  }, [setTitle])
  

  // useEffect(() => {
  //   // Fetch existing training data
  //   const fetchTraining = async () => {
  //     try {
  //       const response = await axios.get(api+`/getDetailFormation/${id}`);
  //       const training = response.data;
  //       console.log(response.data)
  //       setFormValues({
  //         titre: training.titre,
  //         description: training.description,
  //         duree: training.duree,
  //         dateDebut: training.dateDebut,
  //         dateFin: training.dateFin,
  //         lieu: training.lieu,
  //         photo: '',
  //         organisation: training.organisation,
  //         user_id: training.user_id,
  //       });
  //       setTrainers(training.trainers || []);
  //     } catch (error) {
  //       console.log('Erreur lors du chargement de la formation:', error);
  //       // alert('Une erreur s\'est produite lors du chargement de la formation.');
  //     }
  //   };

  //   fetchTraining();
  // }, [id]);

  // const handleInputChange = (e) => {
  //   const { titre, value } = e.target;
  //   setDataFormation({ ...dataFormation, [titre]: value });
  // };

  const handleInputChange = (e) => {
    setDataFormation({
      ...dataFormation,
      [e.target.name]: e.target.value,
    });
  }

  const handleFileChange = (e) => {
    setFormValues({ ...formValues, photo: e.target.files[0] });
  };

  const addTrainer = () => {
    if (selectedTrainer && !trainers.includes(selectedTrainer)) {
      setTrainers([...trainers, selectedTrainer]);
      setSelectedTrainer('');
      setIsModalOpen(false);
    }
  };

  const removeTrainer = (index) => {
    const updatedTrainers = trainers.filter((_, i) => i !== index);
    setTrainers(updatedTrainers);
  };

  const handleSubmit = async () => {
    // const formData = new FormData();
    // for (const key in formValues) {
    //   formData.append(key, formValues[key]);
    // }
    // formData.append('trainers', trainers);

    try {
      const response = await axios.post(api + `/api/modFormation/${id}`, dataFormation)
      .then(response => {
        console.log('Formation modifiée avec succès!');
      })
      .catch(error => {
        console.error("Erreur lors de la modification des formations:", error)
      })
       
    } catch (error) {
      console.error('Erreur lors de la modification de la formation:', error);
      alert('Une erreur s\'est produite lors de la modification de la formation.');
    }
  };

  return (
    <>
      {/* <Header title="Modification de Formation" /> */}
      <div className='border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]'>
        <div className='flex w-full justify-between items-center mb-6'>
        <div className="flex items-center gap-2 ">
          <button className={"  border  rounded-full p-2 w-[34px] "} onClick={()=>navigate('/formateur/formation')} >
            {/* <img src={arrowleft} alt="arrow" className='w-5 h-5'/> */}
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h1>Modifier la formation</h1>
      </div>
          
          <button className='btn-black' onClick={handleSubmit}>Enregistrer</button>
        </div>

        <div className="grid grid-cols-3 gap-3">
          <div className="col-span-1">
            <div className='border-line px-10 py-5 text-start gap-y-5'>
              <ul className='flex-col flex gap-6'>
                <li>Information générale</li>
                <li>Formateur</li>
              </ul>
            </div>
          </div>

          <div className="col-span-2">
            {/* Information générale */}
            <div className='border-line p-4 mb-4'>
              <h5 className='text-lg font-medium mb-4 text-start'>Information générale</h5>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                <div className='input-form'>
                  <label htmlFor="titre">Titre</label>
                  <input
                    type="text"
                    id="titre"
                    name="titre"
                    className="input"
                    placeholder='Ecrire le titre'
                    value={dataFormation.titre}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='input-form'>
                  <label htmlFor="duree">Durée</label>
                  <input
                    type="text"
                    id="duree"
                    name="duree"
                    className="input"
                    placeholder='Durée en minutes'
                    value={dataFormation.duree}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='grid grid-cols-2 gap-4'>
                <div className='input-form'>
                  <label htmlFor="dateDebut" className="block text-sm font-medium text-gray-700 text-start">Date début</label>
                  <input
                    type="date"
                    id="dateDebut"
                    name="dateDebut"
                    className="input"
                    value={dataFormation.dateDebut}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='input-form'>
                  <label htmlFor="dateFin" className="block text-sm font-medium text-gray-700 text-start">Date fin</label>
                  <input
                    type="date"
                    id="dateFin"
                    name="dateFin"
                    className="input"
                    value={dataFormation.dateFin}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className='input-form'>
                <label htmlFor="lieu" className="block text-sm font-medium text-gray-700 text-start">Lieu</label>
                <input
                  type="text"
                  id="lieu"
                  name="lieu"
                  className="input"
                  placeholder='Lieu de la formation'
                  value={dataFormation.lieu}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="photo" className="block text-sm font-medium text-gray-700 text-start">Photo</label>
                <input
                  type="file"
                  id="photo"
                  name="photo"
                  className="input"
                  onChange={handleFileChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="organisation" className="block text-sm font-medium text-gray-700 text-start">Organisation</label>
                <input
                  type="text"
                  id="organisation"
                  name="organisation"
                  className="input"
                  placeholder="Nom de l'organisation"
                  value={dataFormation.organisation}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="user_id" className="text-sm font-medium text-gray-700 text-start hidden">User ID</label>
                <input
                  type="hidden"
                  id="user_id"
                  name="user_id"
                  className="input"
                  placeholder="ID de l'utilisateur"
                  value={dataFormation.user_id}
                  onChange={handleInputChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 text-start">Description</label>
                <textarea
                  id="description"
                  name="description"
                  className="input"
                  placeholder='Ajouter une description'
                  value={dataFormation.description}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            {/* Formateur */}
            {/* <div className='border-line p-4 mb-4'>
              <div className='flex justify-between'>
                <h5 className='text-lg font-medium mb-4 text-start'>Formateur</h5>
                <button className='btn-black' onClick={() => setIsModalOpen(true)}>Ajouter Formateur</button>
              </div>

              <div className='grid grid-cols-2 gap-4'>
                {trainers.map((trainer, index) => (
                  <div key={index} className='input-form flex justify-between items-center'>
                    <span>{trainer}</span>
                    <button className='btn-red' onClick={() => removeTrainer(index)}>Supprimer</button>
                  </div>
                ))}
              </div> */}

              {/* Modal for Adding Trainer */}
               {/* {isModalOpen && (
                <div className='modal'>
                  <div className='modal-content'>
                    <h5 className='text-lg font-medium mb-4'>Ajouter un Formateur</h5>
                    <select
                      value={selectedTrainer}
                      onChange={(e) => setSelectedTrainer(e.target.value)}
                      className='input mb-4'
                    >
                      <option value="" disabled>Choisir un formateur</option>
                      {trainers.map((trainer, index) => (
                        <option key={index} value={trainer}>{trainer}</option>
                      ))}
                    </select>
                    <div className='flex justify-end'>
                      <button className='btn-black mr-2' onClick={addTrainer}>Ajouter</button>
                      <button className='btn-red' onClick={() => setIsModalOpen(false)}>Annuler</button>
                    </div>
                  </div>
                </div>
              )} 
            </div> */}
            
          </div>
        </div>
      </div>
    </>
  )
}


export default DetailFormation