import axios from "axios";
import React, {useState} from "react"
import { api } from "../../api";

export default function DiplomaModal({ onClose, onSave }) {
  const [file, setFile] = useState(null);
  const [type, setType] = useState('Diplôme');

  const handleFileChange = (e) => setFile(e.target.files[0]);
  const handleTypeChange = (e) => setType(e.target.value);
  const user_id = 10;

  const handleSubmit = () => {
    if (file) {
      const form = new FormData()
      form.append('fichier', file)
      form.append('type', type)
      form.append('user_id', user_id)

      axios.post(api + '/api/ajoutDiplome', form)
        .then(response =>{
          console.log('Ajout avec succès');
          onClose()
        })
        .catch(error => {
          onClose()
        })
    }
  };



  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-40 z-50">
      <div className="bg-white rounded-lg p-6 w-[400px]">
        <h2 className="text-xl mb-4">Ajouter un diplôme</h2>
          <label className="block mb-2">Télécharger le fichier</label>
          <label className="mb-4">
            <input 
            onChange={handleFileChange}
            type="file" 
            className="
            text-sm text-grey-500 w-full my-1
            file:mr-5 file:py-2 file:px-6
            file:rounded-full file:border-0
            file:text-sm file:font-medium
            file:bg-blue-50 file:text-blue-700
            hover:file:cursor-pointer 
            hover:file:text-blue-800" /> 
          </label>

        <div className="mb-4">
          <label className="block mb-2">Type de document</label>
          <div className="flex gap-4">
            <label>
              <input
                type="radio"
                value="Diplôme"
                checked={type === 'Diplôme'}
                onChange={handleTypeChange}
              />
              Diplôme
            </label>
            <label>
              <input
                type="radio"
                value="Certificat"
                checked={type === 'Certificat'}
                onChange={handleTypeChange}
              />
              Certificat
            </label>
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <button className="btn-white" onClick={onClose}>Annuler</button>
          <button className="btn-black" onClick={handleSubmit}>Ajouter</button>
        </div>
      </div>
    </div>
  );
}
