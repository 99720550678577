import React from 'react';
import img from '../../../assets/Logo.png'
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const navigate = useNavigate()
    return (
        <footer className="w-screen mt-12 bg-slate-700 flex flex-row justify-center items-center p-4">
            <div className='w-8/12 flex flex-row flex-wrap justify-around items-start gap-12 py-6'>
                <div className='flex flex-col items-center'>
                    <img src={img} alt='logo' className='w-[73px]' />
                    <p className='text-white'>
                        NATURA GERME – L'avenir de la formation entrepreneuriale commence ici.
                    </p>
                </div>

                <ul className='text-white flex flex-col gap-y-2'>
                    <li className='font-semibold text-white'>Page</li>
                    <li className='text-slate-400 hover:cursor-pointer' onClick={() => { navigate('/') }}>Accueil</li>
                    <li className='text-slate-400 hover:cursor-pointer' onClick={() => { navigate('/Formation') }}>Formation</li>
                    {
                        localStorage.getItem('naturagerme-id') && (
                            <li className='text-slate-400 hover:cursor-pointer' onClick={() => { navigate('/Profile') }}>Profile</li>
                        )
                    }
                    <li className='text-slate-400 hover:cursor-pointer' onClick={() => { navigate('/apropos') }}>A propos</li>

                </ul>
                <div>

                    <p className='text-white'>
                        Copyright © 2024 <a className='text-primary' href='http://www.haisoa.com'>HAISOA</a>. Tous droit reservé
                    </p>
                </div>

            </div>
        </footer>
    )
}