import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
export default function Indicateur1({  }) {
    const {data,setData}=useOutletContext()

    return (
        <>
            <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="text-xl font-bold w-full text-left"> Entreprise et Famille</span>
                <Select title={"Verse un salaire au propriétaire / gérant"} values={selectInducateur} onChange={(e)=>{setData({...data,i11:e.target.value})}}/>
                
                <Select title={"Verse un salaire aux membres de la famille qui y travaillent"} values={selectInducateur} onChange={(e)=>{setData({...data,i12:e.target.value})}}/>
                <Select title={"ne prend pas d’argent dans la caisse sans reçu"} values={selectInducateur} onChange={(e)=>{setData({...data,i13:e.target.value})}}/>
            </div>
        </>
    )

}