

import React, {useState, useEffect} from 'react'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer'
import axios from 'axios'
import { api } from '../../../api'
import image from "../../../assets/icons/logo_germe.png"
import { useParams } from 'react-router-dom'

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    marginBottom: 10,
    fontSize: 10,
    padding: 5,
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#606060',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    width: '50%',
    borderStyle: 'none'
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#606060',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '70%',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    padding: 5,
  },
  tableColHeader4: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    padding: 5,
  },
  headerLeft:{
    width: '70%',
    border:'none',
    color:'#'
  },
    subtitleLeft:{
    width: '70%',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    padding: 5,
  },
  subtitleRight:{
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    padding: 5,
  },
  headerRight: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#f2f2f2',
    padding: 5,
  },
  tableCol: {
    width: '70%',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
  },
  tableCol2: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
  },
  tableColGray: {
    width: '70%',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
    backgroundColor:'#f2f2f2'
  },
  tableColTitle:{
    backgroundColor: '#f2f2f2',

  },
  tableCol2Gray: {
    width: '30%',
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 5,
    backgroundColor:'#f2f2f2'
  },
  tableCell: {
    fontSize: 10,
  },
  tableCellBold: {
    fontSize: 10,
    fontWeight:'bold',
    textAlign:'center'
  },
  manualSection: {
    marginTop: 20,
  },
  manualRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  manualLabel: {
    width: '50%',
    fontSize: 10,
    paddingRight: 5,
  }, 
   image: {
    width: 100,
    height: 80,
    marginBottom: 20, // Espacement sous l'image

  },
  manualChecked: {
    width: '50%',
    fontSize: 10,
    paddingLeft: 5,
  },
  headerRow : {
    flexDirection: 'row',
  },
  row: {
    flexDirection: 'row',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5
  },
  column: {
    flex: 1,
    fontSize: 10,
  },
  thirdColumn: {
    flex: 1,
    fontSize: 10,
    textAlign: 'start', // Adjust alignment as needed
  },
  headerText: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  tableCell: {
    fontSize: 10,
  },
  tableHeader: {
    backgroundColor: '#f0f0f0',
  },
});

const MyDocument = ({data}) => {
  if (!data.abf_ids) return null;

  return (
  <Document>
    <Page style={styles.page}>

        {/* Tableau 1: coté gauche sans bordure texte centré 
        Côté droite avec bordure text-start
        */}
        <View style={styles.headerRow}>
      <View style={styles.header}>
        {/* insérer une image ici ici un logo */}
        <Text style={{textAlign:'center'}}>        <Image
        style={styles.image}
        src={image}
      /></Text>

        <Text>ANALYSE DES BESOINS EN FORMATION</Text>
      </View>

      <View style={styles.section}>
        <Text style={{marginBottom: 10}}>Numéro de la Fiche: _____________</Text>
        <Text style={{marginBottom: 10}}>Formateur:{data.formateur.nom}</Text>
        <Text style={{marginBottom: 10}}>Organisation chargée de la formation: ________________</Text>
        <Text style={{marginBottom: 10}}>Date de l’évaluation: {new Date()}</Text>
      </View>          
        </View>

      {/* Fin tableu 1 */}

      {/* Section 2 */}
      <View  style={{ padding: 15, backgroundColor: '#f2f2f2' , marginTop:10, marginBottom:15}}>
        <View style={styles.row}>
            <View style={{ width: '50%' }}><Text style={{fontSize:10}} >Nom de l’Entrepreneur: {data.abf_ids.abf1?.user.nom}</Text></View>
            <View  style={{ width: '50%' }}><Text  style={{fontSize:10}}>Pays: {data.abf_ids.abf1?.user.pays}</Text></View>
        </View>
        <Text  style={{fontSize:10, marginTop: 10}}>Nom de l’entreprise : {data.abf_ids?.abf2?.nomEntreprise}</Text>
        </View>
        <View>
            <Text>Indicateurs des compétences en gestion et analyse des besoins en formation</Text>
        </View>
      

//       {/* Tableau principal */}
//       {/* Premier colonne 70% de width, 2emr 30 %*/}
     

        {/* En-tête du tableau */}
        <View style={styles.tableRow}>
          <View style={styles.headerLeft}><Text style={styles.tableCell}></Text></View>
          <View style={styles.headerRight}><Text style={styles.tableCell}>Date de la visite</Text></View>
        </View>

        {/* Exemple de lignes */}
        <View style={styles.table}>
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>1. ENTREPRISE ET FAMILLE</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}></Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>1.1. verse un salaire au propriétaire / gérant</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.entrepriseFamille1}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>1.2. verse un salaire aux membres de la famille qui y travaillent</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.entrepriseFamille2}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>1.3. ne prend pas d’argent dans la caisse sans reçu</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.entrepriseFamille3}</Text></View>
  </View>
  {/* 2. MARKETING */}
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>2. MARKETING</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}></Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>2.1. connaît ses clients, leurs besoins et les opportunités du marché</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.marketing1}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>2.2. assure la promotion de l’entreprise et des produits</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.marketing2}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>2.3. fixe des prix compétitifs et rentables (sur la base des coûts)</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.marketing3}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>3. APPROVISIONNEMENT</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}></Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>3.1. collecte les informations et les prix chez les fournisseurs</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.approvisionnement1}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>3.2. vérifie les marchandises à la livraison et traite bien les marchandises défectueuses</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.approvisionnement2}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>3.3. planifie les approvisionnements selon les besoins de l’entreprise</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.approvisionnement3}</Text></View>
  </View>
  {/* 4. GESTION DES STOCKS */}
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>4. GESTION DES STOCKS</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}></Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>4.1. remplit une fiche de stocks, qui est mise à jour, lisible et claire</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.gestionStock1}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>4.2. fait un inventaire régulier</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.gestionStock2}</Text></View>
  </View>
  {/* 5. COÛTS */}
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>5. COÛTS</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}></Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>5.1. calcule les coûts directs des produits / services</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.cout1}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>5.2. calcule les coûts directs de la main-d’œuvre</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.cout2}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>5.3. calcule les coûts indirects</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.cout3}</Text></View>
  </View>
  {/* 6. TENUE DES REGISTRES */}
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>6. TENUE DES REGISTRES</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}></Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>6.1. tient un journal clair et à jour</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.tenueRegistre1}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>6.2. tient un compte client</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.tenueRegistre2}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>6.3. calcule régulièrement les bénéfices et pertes</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.tenueRegistre3}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>7. PLANIFICATION FINANCIÈRE</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}></Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>7.1. établit les prévisions des ventes et coûts</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.planificationFin1}</Text></View>
  </View>

  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>7.2. planifie les mouvements de caisse</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.planificationFin2}</Text></View>
  </View>

  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>7.3. suit son propre plan d’affaires et l’adapte si nécessaire</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.planificationFin3}</Text></View>
  </View>
  {/* 8. PERSONNEL ET PRODUCTIVITÉ */}
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>8. PERSONNEL ET PRODUCTIVITÉ</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}></Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>8.1. maîtrise les facteurs et indicateurs de production</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.persoProd1}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>8.2. sait recruter les personnes productives</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.persoProd2}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>8.3. encourage la productivité du personnel</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf1?.persoProd3}</Text></View>
  </View>
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>TOTAL DES POINTS</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}> {calculateTotalPoints(data.abf_ids.abf1)}</Text></View>
  </View>
  <View style={styles.container}>
    <View style={styles.column}>
      <Text>Clé pour l’activité GERME recommandée :</Text>
    </View>
    <View style={styles.column}>
      <Text>Total des points : 0-51</Text>
      <Text>Total des points : 52-62</Text>
      <Text>Total des points : 63-72</Text>
    </View>
    <View style={styles.thirdColumn}>
      <Text>SFE (Séminaire de Formation d’Entrepreneur)</Text>
      <Text>GAE (Groupe d’Amélioration des Entreprises)</Text>
      <Text>EI (Encadrement Individuel)</Text>
    </View>
  </View>
</View>



      {/* Section Manuels GERME/ AVEC bordure */}
      <View style={{marginTop:20, marginBottom:20}}>
  <Text style={{fontSize: 12, fontWeight: 'bold'}}>MANUELS GERME EN POSSESSION DE L’ENTREPRENEUR</Text>
  <Text style={{fontStyle:'italic', fontSize:10}}>(vérifié par le formateur)</Text>
</View>

<View style={styles.tableRow}>
  <View style={styles.headerLeft}><Text style={styles.tableCell}></Text></View>
  <View style={styles.headerRight}><Text style={styles.tableCell}>Date de la visite</Text></View>
</View>

<View style={styles.table}>
  <View style={styles.tableRow}>
    <View style={styles.subtitleLeft}><Text style={styles.tableCellBold}>MANUELS GERME</Text></View>
    <View style={styles.subtitleRight}><Text style={styles.tableCell}></Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>Manuel de base : Marketing</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.manuelMarketing}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>Manuel de base : Approvisionnement</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.manuelApprov}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>Manuel de base : Gestion de stocks</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.manuelGestStock}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>Manuel de base : Coûts</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.manuelCout}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>Manuel de base : Comptabilité</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.manuelCompta}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>Manuel de base : Planification financière</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.manuelPlanFin}</Text></View>
  </View>
  
  {/* Si c'est une duplication, assurez-vous de corriger le label ou de vérifier si c'est intentionnel */}
  {/* <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>Manuel de base : Planification financière</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.manuelPersoProd}</Text></View>
  </View> */}
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>Manuel de base : Personnel et Productivité</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.manuelPersoProd}</Text></View>
  </View>
</View>

<View style={{marginTop:20, marginBottom:20}}>
  <Text style={{fontSize: 12, fontWeight: 'bold'}}>AUTRES BESOINS QUE LA FORMATION GERME</Text>
  <Text style={{fontStyle:'italic', fontSize:10}}>(selon la propre perception de l’entrepreneur)</Text>
</View>

<View style={styles.table}>
  <View style={styles.tableRow}>
    <View style={styles.headerLeft}><Text style={styles.tableCell}></Text></View>
    <View style={styles.headerRight}><Text style={styles.tableCell}>Date de la visite</Text></View>
  </View>

  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>Manuel de base :</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.manuelDeBase}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>1. Informations sur le marché</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.infoMarche}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>2. Registre du commerce</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.registreCom}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>3. Assistance juridique</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.assistanceJur}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>4. Informations sur la technologie</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.infoTechno}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>5. Formation technique</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.formationTech}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>6. Assurances</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.assurance}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>7. Crédits</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.credit}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>8. Devises étrangères</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.deviseEtrangere}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>9. Matières premières</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.besoin9}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>10. Associations/Groupements</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.associationGroup}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>11. Autre (précisez) :</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.autre1}</Text></View>
  </View>
  
  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}>12. Autre (précisez) :</Text></View>
    <View style={styles.tableCol2}><Text style={styles.tableCell}>{data.abf_ids.abf2?.autre2}</Text></View>
  </View>

  <View style={styles.tableRow}>
    <View style={styles.tableCol}><Text style={styles.tableCell}></Text></View>
    <View style={styles.tableCol2}>
      <Text style={styles.tableCell}>
        Clé de notation :{"\n"}
        0 = Besoin non satisfait{"\n"}
        1 = Besoin partiellement satisfait{"\n"}
        2 = Besoin satisfait{"\n"}
        x = Pas de besoin dans le domaine
      </Text>
    </View>
  </View>
</View>

<View style={{marginTop:20, marginBottom:20}}>
        <Text style={{fontSize: 12, fontWeight: 'bold'}}>PROGRAMME DE FORMATION ET PARTAGE DES COÛTS</Text>
       </View>

<View style={styles.table}>
        {/* En-tête du tableau */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <Text style={[styles.tableCol, styles.headerText, { flex: 4 }]}>FORMATIONS GERME</Text>
          <Text style={[styles.tableCol, styles.headerText, { flex: 2 }]}>Volonté de payer sa part avant la formation (montant en AR)</Text>
          <Text style={[styles.tableCol, styles.headerText, { flex: 2 }]}>Date de participation</Text>
          <Text style={[styles.tableCol, styles.headerText, { flex: 2 }]}>Montant payé par l’entrepreneur</Text>
          <Text style={[styles.tableCol, styles.headerText, { flex: 2 }]}>Contribution de l’entrepreneur en pourcentage de coût</Text>
        </View>

       {/* Ligne de données */}
       <View style={styles.tableRow}>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 4 }]}>Séminaire de Formation d’entrepreneurs</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form1Montant1}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form1Date}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form1Montant2}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form1Pourcentage}</Text>
                </View>

                <View style={styles.tableRow}>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 4 }]}>Séminaire de recyclage</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form2Montant1}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form2Date}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form2Montant2}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form2Pourcentage}</Text>
                </View>

                <View style={styles.tableRow}>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 4 }]}>Session de groupe d’amélioration des entreprises (1)</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form3Montant1}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form3Date}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form3Montant2}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form3Pourcentage}</Text>
                </View>

                <View style={styles.tableRow}>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 4 }]}>Session de groupe d’amélioration des entreprises (2)</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form4Montant1}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form4Date}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form4Montant2}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form4Pourcentage}</Text>
                </View>

                <View style={styles.tableRow}>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 4 }]}>Session de groupe d’amélioration des entreprises (3)</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form5Montant1}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form5Date}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form5Montant2}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form5Pourcentage}</Text>
                </View>

                <View style={styles.tableRow}>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 4 }]}>Session d’encadrement Individuel (1)</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form6Montant1}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form6Date}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form6Montant2}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form6Pourcentage}</Text>
                </View>

                <View style={styles.tableRow}>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 4 }]}>Session d’encadrement Individuel (2)</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form7Montant1}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form7Date}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form7Montant2}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form7Pourcentage}</Text>
                </View>

                <View style={styles.tableRow}>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 4 }]}>Session d’encadrement Individuel (3)</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form8Montant1}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form8Date}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form8Montant2}</Text>
                    <Text style={[styles.tableCol, styles.tableCell, { flex: 2 }]}>{data.abf_ids.abf3?.form8Pourcentage}</Text>
                </View>
      </View>

    </Page>
  </Document>
)}

const calculateTotalPoints = (abf1) => {
  if (!abf1) return 0;

  return (
    abf1.entrepriseFamille1 +
    abf1.entrepriseFamille2 +
    abf1.entrepriseFamille3 +
    abf1.marketing1 +
    abf1.marketing2 +
    abf1.marketing3 +
    abf1.approvisionnement1 +
    abf1.approvisionnement2 +
    abf1.approvisionnement3 +
    abf1.gestionStock1 +
    abf1.gestionStock2 +
    abf1.gestionStock3 +
    abf1.cout1 +
    abf1.cout2 +
    abf1.cout3 +
    abf1.tenueRegistre1 +
    abf1.tenueRegistre2 +
    abf1.tenueRegistre3 +
    abf1.planificationFin1 +
    abf1.planificationFin2 +
    abf1.planificationFin3 +
    abf1.persoProd1 +
    abf1.persoProd2 +
    abf1.persoProd3
  );
}

const Fiche = () => {

  const {id} = useParams()
  // const [data, setData] = useState({
  //     manuels: [],
  //     besoins: [],
  //     sessions: []
  //   })
  const [datas, setDatas] = useState({})
  const [formateur, setFormateur] = useState({})
  
    useEffect(() => {

      const form = new FormData()
      form.append("entrepreneur_id", 8)
      form.append("formation_id", 2)
      form.append("formateur_id", 10)
      axios.post(api+'/api/getABS', form)

        .then(response => {
          // if (response.data && response.data.formation && response.data.formation.length > 0) {
            setFormateur(response.data.formateur)
            setDatas(response.data)
            // setFormateur(response.data.abf_ids)
            // console.log("Formateur" +formateur+" data:"+data)
            // console.log("Formateur" +formateur.email+" data:"+data)
          // }
          console.log(datas)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }, [])

    return(<PDFDownloadLink document={<MyDocument data={datas} />} fileName="rapport_analyse_besoins.pdf" className="btn-black">
  {({ loading }) => (loading ? 'Chargement du document...' : 'Imprimer une fiche')}
</PDFDownloadLink>)}



export default Fiche

