import React, { useEffect, useState } from 'react';
import img from '../../../assets/black.jpg'
import CardFormation from '../../components/card_formation';
import Button from '../../components/btn/Button';
import Input from '../../components/form/Input';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../api';
import axios from 'axios';

export default function Formation() {
    const navigate = useNavigate()
    const [search, setSearch] = useState('')
    const [data, setData] = useState([
    ]);

    function go(item) {

        const id = localStorage.getItem('naturagerme-id')
        if (id) {
            navigate("/ABF/" + item)
        } else {
            navigate("/Login")
        }
    }

    useEffect(() => {
        // Remplacer cette URL par l'URL de votre API Laravel
        axios.get(api + '/api/getAllFormations')
            .then(response => {
                setData(response.data);
                // console.log(response.data);
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des formations:", error);
            });
    }, []);

    return (
        <>
            <div className='w-11/12 flex max-lg:flex-col lg:flex-row justify-between max-lg:items-start lg:items-end '>
                <span className='text-[#2C2F24] font-bold'>Formation</span>
                <div className='w-[400px] flex flex-row justify-end items-end  gap-x-3'>
                    <Input placeholder={"Recherche ..."} value={search} onChange={(e) => { setSearch(e.target.value) }} />
                    <Button title={"Rechercher"} />
                </div>
            </div>
            <div className='w-11/12 grid max-md:grid-cols-2 max-lg:grid-cols-3 lg:grid-cols-5 justify-start items-center gap-5'>
                {
                    data.filter((item) => (search !== '' ? search.toLowerCase().includes(item.titre.toLowerCase()) : item)).map((item, index) => (
                        <button onClick={() => { go(item.id) }}>
                            <CardFormation item={item} key={index} />
                        </button>
                    ))
                }
            </div>
        </>
    )
}