import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderContact from '../components/header_contact';
import Header from '../components/header'
import Footer from '../components/footer';
import '../index.css'
export default function Layout() {
    return (
        <div className="w-screen flex flex-col items-center gap-y-3">
            <HeaderContact/>
            <Header></Header>
            <Outlet ></Outlet>
            <Footer></Footer>
        </div>
    )
}