import React, { useEffect, useState } from 'react';
import Input from '../../entrepreneur/components/form/Input';
import Select from '../../entrepreneur/components/form/select';
import { Button } from '@mui/material';
import { useNavigate, useNavigation, useOutletContext } from 'react-router-dom';
import Load from '../../entrepreneur/components/load';
import Notification from '../../entrepreneur/components/feedback/Notification';
import { api } from '../../api';
import axios from 'axios';
// import Button from '../../entrepreneur/components/btn/Button';
import ToLogin from '../../entrepreneur/components/toLogin';

export default function Entreprise({ }) {
    const { setStep, data, setData } = useOutletContext()
    const navigation=useNavigate()
    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })
    function next() {
        // setStep(prev => prev += 1)
        try {
            const form3 = new FormData()
            form3.append("nom", data.nom_entreprise)
            form3.append("dateDemarrage", data.date_demarrage)
            form3.append("adresse", data.adresse_entreprise)
            form3.append("fax", data.fax_entreprise)
            form3.append("tel", data.tel_entreprise)
            form3.append("email", data.email_entreprise)
            form3.append("statutJuridique", data.status_juridique)
            form3.append("domaine", data.domaine_activite)
            // form3.append("dateNaissance", data.date_naissance)
            form3.append("nombreEmploye", data.nombre_employee)
            form3.append("nombreApprentis", data.nombre_apprentis)
            form3.append("nombrePersoTotal", data.nombre_total_personnes)
            form3.append("activiteEntreprise", data.domaine_activite)
            form3.append("user_id", localStorage.getItem("naturagerme-id"))

            axios.post(api + "/api/ajoutDonneeEntreprise", form3)
                .then(res => {
                    console.log(res.data)
                    // navigation('/Login')
                            setStep(prev => prev += 1)
// 
                })
                .catch(err => {
                    console.error(err)
                    setIsOpen({ ...isOpen, fails: true })
                })

        } catch (error) {
            console.error(error)
            setIsOpen({ ...isOpen, fails: true })
        }


    }

    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
    })
    useEffect(() => {
        if (data.tel_entreprise !== '' && data.email_entreprise !== '') {
            setOptions({ ...options, disabled: false })

        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    return (
        <form onSubmit={(e) => { e.preventDefault();next() }} className="w-11/12 flex flex-col gap-y-2">
            <span className="font-black text-3xl">
                Données rélative à l’entreprise
            </span>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Nom de l'entreprise"} type="text" value={data.nom_entreprise} onChange={(e) => { setData({ ...data, nom_entreprise: e.target.value }) }} />
                <Input title={"Date de demarrage"} type='date' value={data.date_demarrage} onChange={(e) => { setData({ ...data, date_demarrage: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Adresse postal"} value={data.adresse_entreprise} onChange={(e) => { setData({ ...data, adresse_entreprise: e.target.value }) }} />
                <Input title={"Fax"} value={data.fax_entreprise} onChange={(e) => { setData({ ...data, fax_entreprise: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Numero de telephone"} value={data.tel_entreprise} onChange={(e) => { setData({ ...data, tel_entreprise: e.target.value }) }} />
                <Input title={"E-mail"} type="email" value={data.email_entreprise} onChange={(e) => { setData({ ...data, email_entreprise: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Select title={"Statut juridique ? "} values={["Entreprise individuelle", "GIE", "SARL", "SA", "Coopérative", "Autre"]} onChange={(e) => { setData({ ...data, status_juridique: e.target.value }) }} />
                <Input title={"Precisez"} type="text" value={data.status_jusidique_autre_preciser} onChange={(e) => { setData({ ...data, status_jusidique_autre_preciser: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Select title={"Domaine d'activités "} values={["Frabricant", "Prestataire", "Grossiste", "Détaillant", "Exploitation agricole"]} onChange={(e) => { setData({ ...data, domaine_activite: e.target.value }) }} />

            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Nombre d'employés "} type="number" value={data.nombre_employee} onChange={(e) => { setData({ ...data, nombre_employee: e.target.value }) }} />
                <Input title={"Nombre d'apprentis"} type="number" value={data.nombre_apprentis} onChange={(e) => { setData({ ...data, nombre_apprentis: e.target.value }) }} />
                <Input title={"Nombre de personnes au total"} type="number" value={data.nombre_total_personnes} onChange={(e) => { setData({ ...data, nombre_total_personnes: e.target.value }) }} />
            </div>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Decrivez brièvement l'activité de votre entreprise"} type="text" value={data.description} onChange={(e) => { setData({ ...data, description: e.target.value }) }} />
            </div>
            <div className="w-full flex max-lg:flex-col-reverse gap-y-2 justify-between item-center">
            <ToLogin/>
                {/* <Button variant='contained' onClick={()=>{next()}}>Suivant</Button> */}
                <Button type="submit" disabled={options.disabled} sx={{
                    bgcolor: "#FF7D00",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#FF9933", // Change the hover background color
                        color: "white", // Change the hover text color
                    },
                }} variant='contained' className="px-9 ">
                    <Load title={"Suivant"} loading={options.loading} next={true} />
                </Button>
                <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
            </div>
        </form>
    )
}