import React, { useState } from 'react';
import Input from '../entrepreneur/components/form/Input';
import Card from '../entrepreneur/components/Card';
import Account from './Account';
import Verification from './Verification';
import Personnel from './Personnel';
import Personnel2 from './Personnel2'
import Entreprise from './entreprise';
import Desire from './Desire';
import { useOutletContext } from 'react-router-dom';
import { Button } from '@mui/material';

export default function Signup() {
    const [data, setData] = useState({

    })
    const {step} = useOutletContext()
    const [page, setPage] = useState(1)
    
    return (
        <div className='w-full h-full flex flex-col justify-center items-center'>
            
            <div className='max-lg:w-full lg:w-10/12  rounded-[5px] flex  justify-center '>
            
                {
                    step == 0 && (
                        <>
                            <Account />

                        </>
                    )
                }
                {
                    step == 1 && (
                        <Verification />
                    )
                }
                {
                    step == 2 && (
                        <>
                            {
                                page === 1 && (
                                    <>
                                        <Personnel setPage={setPage} />

                                    </>
                                )
                            }
                            {
                                page === 2 && (
                                    <>
                                        <Personnel2 setPage={setPage} />

                                    </>
                                )
                            }
                            {
                                page === 3 && (
                                    <>
                                        <Entreprise />

                                    </>
                                )
                            }


                        </>
                    )
                }
                {
                    step == 3 && (
                        <Desire />
                    )
                }
            </div>
        </div>
    )
}