import React from 'react';
// import Button from '../../../components/btn/Button';\
// Button
import img from '../../../../assets/reunion.jpg'
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function Introduction() {
    const navigate=useNavigate()
    return (
        <div className="w-full flex lg:flex-row max-lg:flex-col-reverse gap-y-10 justify-between items-center ">
            <div className="lg:w-5/12 max-lg:w-full flex flex-col gap-y-4">
                <span className='text-3xl font-semibold'>
                    Optimiser la formation des entrepreneurs avec efficacité
                </span>
                <p>
                    Transformez vos compétences et faites croître votre entreprise grâce à NATURA GERME, la solution innovante pour analyser les besoins en formation et gérer les sessions de formation.
                </p>
                <div className='flex flex-row gap-x-4'>
                    <Button variant='contained' style={{backgroundColor:'#1e293b'}} onClick={()=>{navigate('/Login')}}>Se connecter</Button>
                    <Button variant="outlined" style={{borderColor:'#1e293b',color:'#1e293b'}}  onClick={()=>{navigate('/Signup')}}>S'inscrire</Button>
                </div>
                {/* <faSearch></faSearch> */}
            </div>
            <img src={img} alt='none' className="max-lg:w-full lg:w-6/12 rounded-[8px]" />
        </div>
    )
}