import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
export default function Indicateur2({ data, setData }) {

    return (
        <>
            <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title text-left w-full">Marketing</span>
                <Select title={"Connaît ses clients, leurs besoins et les opportunités du marché"} values={selectInducateur} onChange={(e)=>{setData({...data,i21:e.target.value})}}/>
                <Select title={"Assure la promotion de l’entreprise et des produits"} values={selectInducateur} onChange={(e)=>{setData({...data,i22:e.target.value})}}/>
                <Select title={"Fixe des prix compétitifs et rentables (sur la base des coûts)"} values={selectInducateur} onChange={(e)=>{setData({...data,i23:e.target.value})}}/>
            </div>
        </>
    )

}