import React, { useEffect, useState } from 'react'
import Input from '../../entrepreneur/components/form/Input'
import { Button } from '@mui/material'
import Load from '../../entrepreneur/components/load'
import Notification from '../../entrepreneur/components/feedback/Notification'
import axios from 'axios'
import { api } from '../../api'
import { Code } from '../../script/generator'

export default function VerificationCode({ data, setData, setPage }) {
    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })
    const [isOpen, setIsOpen] = useState({
        fails: false
    })

    function handleSubmit(e) {
        e.preventDefault()
        console.log(data)
        setOptions({ ...options, loading: true })
        // setPage(prev => prev += 1)
        if (data.code == data.codeGenerated.toString()) {
            setOptions({ ...options, loading: false })
            setPage(prev => prev += 1)
        } else {
            setIsOpen({ ...isOpen, fails: true })
            setOptions({ ...options, loading: false })
            // setData({...data, codeGenerated: '' })
        }
    }

    useEffect(() => {
        if (data.code !== '') {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    return (
        <form onSubmit={(e) => { handleSubmit(e) }} className='w-full flex flex-col gap-y-3'>
            <p>Nous avons envoyé une code de verification à votre adresse e-mail</p>
            <Input title={"Code de verification"} required={true} type='number' value={data.code} onChange={(e) => { setData({ ...data, code: e.target.value }) }} />
            <Button style={{backgroundColor:'#1e293b'}} fullWidth type="submit" variant='contained' disabled={options.disabled}>
                <Load title={'Verifier'} loading={options.loading} />
            </Button>
            <Notification isOpen={isOpen.fails} type='error' message="Une erreur s'est produit." onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
        </form>
    )
}