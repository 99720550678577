import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
export default function Indicateur7() {
const {data,setData}=useOutletContext()
    return (
        <>
            <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title text-left w-full">Planification financière</span>
                <Select title={"Etablit les prévisions des ventes et coûts"} values={selectInducateur} onChange={(e)=>{setData({...data,i71:e.target.value})}}/>
                <Select title={"Planifie les mouvements de caisse"} values={selectInducateur} onChange={(e)=>{setData({...data,i72:e.target.value})}}/>
                <Select title={"Puit son propre plan d’affaires et l’adapte si nécessaire"} values={selectInducateur} onChange={(e)=>{setData({...data,i73:e.target.value})}}/>
            </div>
        </>
    )

}