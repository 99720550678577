import React from 'react';
import Introduction from './Introduction';
// import Button from '../../components/btn/Button';
import img from '../../../assets/analyse.jpg'
import Formation from './Formation';
import Pourquoi from './Pourquoi';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Details from './Details';

export default function LandingPage() {

    const navigate = useNavigate()
    return (
        <>
            <div className='w-11/12 flex flex-col gap-y-9'>
                <Introduction />

            </div>
            <div className="w-screen flex  justify-center bg-slate-50 py-8">
                <div className="w-11/12 flex max-lg:flex-col-reverse lg:flex-row-reverse  justify-between items-center">
                    <div className="max-lg:w-full lg:w-5/12 flex flex-col gap-y-4">
                        <span className='text-3xl  font-semibold'>
                            Analyse précise des besoins en formation
                        </span>
                        <p>
                            Découvrez les lacunes de vos compétences et identifiez les domaines à améliorer avec notre questionnaire d'évaluation détaillé. NATURA GERME analyse vos réponses et vous fournit des recommandations personnalisées pour une formation sur mesure, adaptée à vos besoins spécifiques.
                        </p>

                    </div>
                    <img src={img} alt='none' className="max-lg:w-full lg:w-6/12 rounded-[8px]" />
                </div>
            </div>
            <Details></Details>

            <Formation />

            <Pourquoi />
            <div className="w-11/12 my-12">
                <p className="text-[#2C2F24] py-2 text-3xl font-semibold">
                    Rejoignez la révolution de la formation entrepreneuriale !
                </p>
                <p className='py-4'>
                    Inscrivez-vous dès maintenant sur NATURA GERME pour transformer vos compétences et faire passer votre entreprise au niveau supérieur. Que vous soyez entrepreneur en quête de croissance ou formateur prêt à partager votre expertise, nous avons les outils qu'il vous faut pour réussir.


                </p>
                <div className="flex flex-row gap-x-4">
                    <Button className='' variant='outlined' onClick={() => { navigate('/Signup') }}>
                        [Inscrivez-vous]
                    </Button>
                    <Button variant='outlined' onClick={() => { navigate('/Signup') }}>
                        [Devenez Formateur]
                    </Button>

                    <Button variant='outlined'>
                        [En Savoir Plus]
                    </Button>
                </div>
            </div>

        </>
    )
}