import React from 'react';

export default function Details() {
    return (
        <div className='w-11/12 flex flex-row flex-wrap justify-center items-start gap-5 py-4'>
            <div className='w-[350px] bg-gradient-to-r from-blue-500 to-green-500 text-white p-5 gap-y-10 rounded-[8px] shadow-md shadow-slate-400/40 flex flex-col justify-center items-center'>

                <span className='text-[28px] font-semibold w-full text-center leading-7'>
                    “INSCRIPTION SIMPLIFIER”
                </span>
                <ul className="flex flex-col gap-y-5 text-left text-[18px]">
                    <li>
                        <b>▪ Pour les entrepreneurs :</b> Inscrivez-vous facilement aux formations
                        recommandées et accédez à des programmes qui vous aideront à développer
                        vos compétences et à atteindre vos objectifs professionnels
                    </li>
                    <li>
                        <b> ▪ Pour les formateurs :</b> Rejoignez notre réseau d'experts, mettez à jour vos
                        compétences et disponibilités, et proposez des formations sur mesure pour
                        répondre aux besoins des entrepreneurs
                    </li>
                </ul>
            </div>
            <div className='w-[350px] bg-gradient-to-r from-yellow-500 to-orange-500 text-white p-5 gap-y-10 rounded-[8px] shadow-md shadow-slate-400/40 flex flex-col justify-center items-center'>

                <span className='text-[28px] font-semibold w-full text-center leading-7'>
                    “GENERATION DE RAPPORTS”
                </span>
                <ul className="text-left text-[18px] text-white ">
                    <li>
                        Après chaque analyse des besoins, NATURA GERME permet aux formateurs de
                        créer des rapports détaillés. Ces rapports contiennent des recommandations
                        personnalisées, des objectifs de formation, et des plans d'action, facilitant ainsi
                        la mise en place de formations efficaces et ciblées.
                    </li>
                </ul>

            </div>
            <div className='w-[350px] bg-gradient-to-r from-purple-500 to-pink-500 text-white p-5 gap-y-10 rounded-[8px] shadow-md shadow-slate-400/40 flex flex-col justify-center items-center'>

                <span className='text-[28px] font-semibold'>
                    “FACILITES”
                </span>
                <ul className="flex flex-col gap-y-5 text-left text-[18px]">
                    <li>
                        <b> ▪ Analyse automatisée des besoins :</b> Détectez facilement les domaines de
                        formation essentiels pour un développement optimal.
                    </li>
                    <li>
                        <b> ▪ Gestion des inscriptions :</b> Réservez vos places ou inscrivez des entrepreneurs
                        aux sessions de formation.
                    </li>
                    <li>
                        <b> ▪ Suivi des progrès :</b> Suivez l'impact des formations et mesurez les
                        améliorations des compétences.
                    </li>
                </ul>

            </div>
        </div>
    );
}