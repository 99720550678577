import React from 'react';
import Select from '../../Select'
import { selectManuel } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
export default function Manuel() {
const {data,setData}=useOutletContext()
    return (
        <>
            <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title w-full text-left">Manuels GERME</span>
                <Select title={"Manuel de base :	Marketing"} values={selectManuel} onChange={(e)=>{setData({...data,Marketing:e.target.value})}}/>
                <Select title={"Manuel de base :	Approvisionnement"} values={selectManuel} onChange={(e)=>{setData({...data,Approvisionnement:e.target.value})}}/>
                <Select title={"Manuel de base :	Gestion de stocks"} values={selectManuel} onChange={(e)=>{setData({...data,gestionStocks:e.target.value})}}/>
                <Select title={"Manuel de base :	Coûts"} values={selectManuel} onChange={(e)=>{setData({...data,couts:e.target.value})}}/>
                <Select title={"Manuel de base :	Comptabilité"} values={selectManuel} onChange={(e)=>{setData({...data,Comptabilité:e.target.value})}}/>
                <Select title={"Manuel de base :	Planification financière"} values={selectManuel} onChange={(e)=>{setData({...data,planuficationFinanciere:e.target.value})}}/>
                <Select title={"Manuel de base :	Personnel et Productivité"} values={selectManuel} onChange={(e)=>{setData({...data,personnel:e.target.value})}}/>
            </div>
        </>
    )

}