import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
export default function Indicateur4() {
const {data,setData}=useOutletContext()
    return (
        <>
            <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title text-left w-full">Gestion des Stocks</span>
                <Select title={"remplit une fiche de stocks, qui est mise à jour, lisible et claire"} values={selectInducateur} onChange={(e)=>{setData({...data,i41:e.target.value})}}/>
                <Select title={"remplit une fiche de stocks, qui est mise à jour, lisible et claire"} values={selectInducateur} onChange={(e)=>{setData({...data,i42:e.target.value})}}/>
                <Select title={"a un stock suffisant sans marchandises défectueuses"} values={selectInducateur} onChange={(e)=>{setData({...data,i43:e.target.value})}}/>
            </div>
        </>
    )

}