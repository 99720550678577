import React, { useEffect, useState } from 'react'
import user1 from '../../../assets/icons/icons8-account-48.png'
import Header from '../../components/Header'
import file from '../../../assets/icons/eye-svgrepo-com.svg'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { api } from '../../../api'
import axios from 'axios'
import NoResults from '../../components/NoResults'

const Entrepreneur = () => {

  const navigate = useNavigate(); 
  const user_id = 10

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const {setTitle} = useOutletContext()

  useEffect(() => {
    setLoading(true); // Commence le chargement
    axios.get(api + `/api/listeInscriptionsFormateur/${user_id}`)
      .then(response => {
        setData(response.data); // Mise à jour de l'état avec les données récupérées
        setLoading(false)
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des formations:", error); // Gestion des erreurs
        setLoading(false)
      })
      setTitle("Entrepreneur")
  }, [user_id, setTitle])

  return (
    <>
      {/* <Header title={"Entrepreneur"} /> Composant Header avec le titre "Entrepreneur" */}
      <div className='border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]'>
        <div className="flex flex-col gap-2 text-start mb-6">
          <h5 className='text-xl font-semibold'>Liste des entrepreneurs</h5> {/* Titre de la section */}
          <p>Retrouvez ici la liste des entrepreneurs</p> {/* Description de la section */}
        </div>
        <div>
          {loading ? ( // Affichage du composant de chargement pendant la récupération des données
           <div className="flex justify-center items-center mt-60">
           {/* <div className="w-16 h-16 border-4 border-t-4 border-t-transparent border-gray-600 rounded-full animate-spin"></div> */}
           <div className="animate-spin rounded-full h-16 w-16 border-t-4 border border-t-gray-900"></div>
         </div>
          ) : (
            data.length > 0 ? ( 
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div className="overflow-hidden border rounded-md">
                      <table className="min-w-full text-left text-sm font-light text-surface dark:text-white">
                        <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
                          <tr>
                            <th scope="col" className="px-6 py-4">Entrepreneur</th>
                            <th scope="col" className="px-6 py-4">Sexe</th>
                            <th scope="col" className="px-6 py-4">Fonction</th>
                            <th scope="col" className="px-6 py-4">Adresse</th>
                            <th scope="col" className="px-6 py-4">Contact</th>
                            <th scope="col" className="px-6 py-4">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((entrepreneur, index) => ( // Boucle sur les données pour afficher chaque entrepreneur
                            <tr key={index}>
                              <td className="flex px-6 py-4 font-medium gap-4">
                              <img 
                                src={api + '/storage/' + entrepreneur.user.photo} 
                                alt={entrepreneur.user.nom} 
                                className='w-12 h-12 rounded-full border bg-slate-100'
                                onError={(e) => { e.target.onerror = null; e.target.src = user1; }}
                              />
                                <div className="flex flex-col justify-between gap-2">
                                  <p>{entrepreneur.user.nom}</p> {/* Nom de l'entrepreneur */}
                                  <p>{entrepreneur.user.email}</p> {/* Email de l'entrepreneur */}
                                </div>
                              </td>
                              <td>{entrepreneur.user.donnee_perso1.sexe}</td> 
                              <td>{entrepreneur.user.donnee_perso1.fonctionEntreprise}</td>
                              <td>{entrepreneur.user.donnee_perso1.adressePostale}</td>
                              <td>{entrepreneur.user.donnee_perso1.tel}</td>
                              <td>
                                <button className='btn-black-outline' onClick={() => navigate('/formateur/entrepreneur/'+entrepreneur.user.id)}> {/* Bouton pour naviguer vers les détails de l'entrepreneur */}
                                  <img src={file} alt="..." className='w-5 h-5' /> {/* Icône du bouton */}
                                  Détail
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <NoResults showMessage={true} /> // Affichage du composant NoResults si aucune donnée n'est disponible
            )
          )}
        </div>
      </div>
    </>
  );
}

export default Entrepreneur;
