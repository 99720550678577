import React, { useEffect, useState } from 'react';
import user1 from '../../../assets/users/prestataire1.png';
import Header from '../../components/Header'
import Diplome from './partials/Diplome'
import axios from 'axios'
import { api } from '../../../api'
import userIcon from '../../../assets/images/icons8-test-account-96.png'
import Notification from '../../../entrepreneur/components/feedback/Notification'
import Modals from '../../../entrepreneur/components/modal'
import { Button } from '@mui/material'
import PasswordModal from '../../components/PasswordModal'
import { useOutletContext } from 'react-router-dom';

const Parametre = () => {
  // État pour gérer les informations de l'utilisateur
  const user_id = localStorage.getItem('naturagerme-id');
  const [userInfo, setUserInfo] = useState({
        "id": "",
        "nom": "",
        "prenom": "",
        "nomJeuneFille": "",
        "adressePostale": "",
        "tel": "",
        "pere": "",
        "mere": "",
        "sexe": "",
        "dateNaissance": "",
        "fax": "",
        "niveauFormation": "",
        "fonctionEntreprise": "",
        "pays": "",
  });

  const [userCompetence, setUserCompetence] = useState({
    "id": "",
    "maitriseDomaine": "",
    "experiencePratique": "",
    "techniqueTransmission": "",
    "capacite": "",
    "experienceProf": "",
    "qualitePerso": "",
    "user_id": user_id
});

// const [userCompetence, setUserCompetence] = useState([]);

  const [userProfile, setUserProfile] = useState([])
  const [isFormValid, setIsFormValid] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [activeForm, setActiveForm] = useState(null)
  const {setTitle} = useOutletContext()
  const [isOpen, setIsOpen] = useState({
    success: false,
    fails: false
})
  const getProfileFormateur = () => {

    axios.get(api + `/api/getProfilFormateur/${user_id}`)
    .then(response =>{
      setUserInfo(response.data.donnee_perso1);
      setUserProfile(response.data)
    })
    .catch(error => {

    })
  }

  const getCompetenceFormateur = () => {
    axios.get(api + `/api/getCompetenceFormateur/${user_id}`)
    .then(response =>{

      if (response.data.competences[0])
      {
        setUserCompetence(response.data.competences[0]);
      }
    })
    .catch(error => {
    })
  }

  const handleInfoSubmit = (formId) => {
    setActiveForm(formId);
    setIsModalOpen(true);
  }

  const confirmModification = () => {
    if (activeForm === 'personalInfo') {
      axios.post(api + `/api/modDonneePerso1`, userInfo)
        .then(response => {
          setIsModalOpen(false)
          setIsOpen({...isOpen, success: true })
          console.log("Données modifiées avec succès");
        })
        .catch(error => {
          setIsModalOpen(false)
          setIsOpen({...isOpen, fails: true })
          console.error("Erreur lors de la modification des données", error);
        });
    } else if (activeForm === 'competence') {
      if (userCompetence.id !== "")
        {
          axios.post(api + `/api/modCompetence/${userCompetence.id}`, userCompetence)
            .then(response =>{
              setIsModalOpen(false)
              setIsOpen({...isOpen, success: true })
              console.log("Modification avec succès")
    
            })
            .catch(error => {
              setIsOpen({...isOpen, fails: true })
            })
        
        }
    
        else{
          axios.post(api + '/api/ajoutCompetence', userCompetence)
            .then(response =>{
              setIsModalOpen(false)
              setIsOpen({...isOpen, success: true })
              console.log("Ajout avec succès")
    
            })
            .catch(error => {
              setIsOpen({...isOpen, fails: true })
            })
        }



      // if (userCompetence.id !== "")
      //   {
      // axios.post(api + `/api/modCompetence`, userCompetence)
      //   .then(response => {
      //     setIsModalOpen(false)
      //     setIsOpen({...isOpen, success: true })
      //     console.log("Compétences modifiées avec succès");
      //   })
      //   .catch(error => {
      //     setIsModalOpen(false)
      //     setIsOpen({...isOpen, success: false })
      //     console.error("Erreur lors de la modification des compétences", error);
      //   });
      // }
    }
    // setIsModalOpen(false);
  };

  const cancelModification = () => {
    setIsModalOpen(false);
  };



  useEffect(() => {
    setTitle('Paramètre')
    
    getProfileFormateur();
    getCompetenceFormateur();

  }, [setTitle])
  


  // État pour gérer le mot de passe
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // Fonction pour gérer les changements dans les champs de texte
  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  }

  const handleCompetenceChange = (e) => {
    setUserCompetence({
      ...userCompetence,
      [e.target.name]: e.target.value,
    });
  };

  // Fonction pour gérer les changements dans les champs de mot de passe
  const handlePasswordChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });
  };

  // Fonction pour soumettre les modifications d'informations
  // const handleInfoSubmit = () => {

  //   axios.post(api + `/api/modDonneePerso1`, userInfo)
  //   .then(response =>{
  //     // console.log('Données modifiées')
  //     getProfileFormateur();

  //   })
  //   .catch(error => {

  //   })
  // }

  // Ajout ou modification competences
  const handleCompetenceSubmit = () => {

    if (userCompetence.id !== "")
    {
      axios.post(api + `/api/modCompetence/${userCompetence.id}`, userCompetence)
        .then(response =>{
          // console.log('Données modifiées')
          // getProfileFormateur();
          getCompetenceFormateur();
          console.log("Modification avec succès")

        })
        .catch(error => {

        })
    
    }

    else{
      axios.post(api + '/api/ajoutCompetence', userCompetence)
        .then(response =>{
          getCompetenceFormateur();
          console.log("Ajout avec succès")

        })
        .catch(error => {

        })
    }
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <>
      {/* <Header title="Paramètre" /> */}
      <div className='border-line mx-4 lg:mx-0 p-6 mt-6 min-h-[570px]'>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div className="col-span-1">
  <div className="border-line px-10 py-5 text-start gap-y-5 sticky top-10  max-w-xs lg:block hidden ">
    <ul className="flex-col flex gap-6 font-medium menu-list ">
              <li onClick={() => scrollToSection('base-info')}>Information de base</li>
              <li onClick={() => scrollToSection('personal-info')}>Information personnel</li>
              <li onClick={() => scrollToSection('skills')}>Compétence</li>
              <li onClick={() => scrollToSection('diplomas')}>Diplôme & Certificat</li>
              <li onClick={() => scrollToSection('password')}>Modifier le mot de passe</li>
              </ul>
            </div>
          </div>

          <div className="col-span-2">
            {/* Information personnelle */}
            <div className='border-line p-4 mb-4' id='base-info'>
              <div className="flex gap-4">
                <img 
                src={api + '/storage/' + userProfile.photo} alt="..." 
                className='w-16 h-16 rounded-full'
                onError={(e) => { e.target.onerror = null; e.target.src = userIcon; }} />
                <div className="flex flex-col justify-between gap-2 items-start">
                  <p className='text-xl text-black-1'>{userInfo.nom}</p>
                  <p>{userProfile.email}</p>
                </div>
              </div>
            </div>

            {/* Formulaire d'information générale */}
            <div className='border-line p-4 mb-4' id="personal-info">
              <h5 className='text-lg mb-4 text-start'>Information générale</h5>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                <div className='input-form'>
                  <label htmlFor="nom">Nom</label>
                  <input
                    type="text"
                    id="nom"
                    name="nom"
                    className="input"
                    value={userInfo.nom}
                    onChange={handleChange}
                  />
                  <input
                    type="hidden"
                    id="id"
                    name="id"
                    className="input"
                    value={userInfo.id}
                    onChange={handleChange}
                  />

                </div>
                <div className='input-form'>
                  <label htmlFor="prenom">Prénom</label>
                  <input
                    type="text"
                    id="prenom"
                    name="prenom"
                    className="input"
                    value={userInfo.prenom}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
              <div className='input-form'>
  <label>Sexe</label>
  <div className='grid grid-cols-2'>
    <label  className='flex gap-2' htmlFor='F'>
      <input
      id='F'
        type="radio"
        name="sexe"
        value="Féminin"
        checked={userInfo.sexe === "Féminin"}
        onChange={handleChange}
      />
      Feminin
    </label>
    <label className='flex gap-2' htmlFor='M'>
      <input
      id='M'
        type="radio"
        name="sexe"
        value="Masculin"
        checked={userInfo.sexe === "Masculin"}
        onChange={handleChange}
      />
      Masculin
    </label>
  </div>
</div>

                <div className='input-form'>
                  <label htmlFor="contact">Contact</label>
                  <input
                    type="text"
                    id="contact"
                    name="contact"
                    className="input"
                    value={userInfo.tel}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                <div className='input-form'>
                  <label htmlFor="profession">Profession</label>
                  <input
                    type="text"
                    id="profession"
                    name="fonctionEntreprise"
                    className="input"
                    value={userInfo.fonctionEntreprise}
                    onChange={handleChange}
                  />
                </div>
                <div className='input-form'>
                  <label htmlFor="adresse">Adresse</label>
                  <input
                    type="text"
                    id="adresse"
                    name="adressePostale"
                    className="input"
                    value={userInfo.adressePostale}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='flex justify-end items-end mt-6'>
                <button className='btn-black' onClick={() => handleInfoSubmit('personalInfo')}>Modifier</button>
              </div>
            </div>

            {/* Formulaire de compétence */}
            <div className='border-line p-4 mb-4'  id="skills">
              <h5 className='text-lg mb-4 text-start'>Compétence</h5>
              <div className='input-form'>
                <label htmlFor="maitrise">Maîtrise du domaine</label>
                <textarea
                  id="maitriseDomaine"
                  name="maitriseDomaine"
                  className="input-textarea"
                  // placeholder='Ajouter des maîtrise de domaines'
                  // value={userCompetence.maitriseDomaine}
                  value={userCompetence.maitriseDomaine}
                  onChange={handleCompetenceChange}
                />
                <input
                  id="id"
                  name="id"
                  className="input hidden"
                  // placeholder='Ajouter des maîtrise de domaines'
                  // value={userCompetence.maitriseDomaine}
                  value={userCompetence.id}
                  onChange={handleCompetenceChange}
                />
                <input
                  id="id"
                  name="id"
                  className="input hidden"
                  // placeholder='Ajouter des maîtrise de domaines'
                  // value={userCompetence.maitriseDomaine}
                  value={userCompetence.user_id}
                  onChange={handleCompetenceChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="experience">Expérience pratique</label>
                <textarea
                  id="experiencePratique"
                  name="experiencePratique"
                  className="input-textarea "
                  placeholder='Ajouter des expériences'
                  value={userCompetence.experiencePratique}
                  onChange={handleCompetenceChange}
                />
              </div>
              <div className='input-form'>
                <label htmlFor="qualite">Qualité</label>
                <textarea
                  id="experiencePratique"
                  name="qualitePerso"
                  className="input-textarea"
                  placeholder='Ajouter une description'
                  value={userCompetence.qualitePerso}
                  onChange={handleCompetenceChange}
                />
              </div>
              {/* <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'> */}
                <div className='input-form'>
                  <label htmlFor="techniqueTransmission">Technique de transmission</label>
                  <textarea
                    type="text"
                    id="techniqueTransmission"
                    name="techniqueTransmission"
                    className="input-textarea"
                    value={userCompetence.techniqueTransmission}
                    onChange={handleCompetenceChange}
                  />
                </div>
                <div className='input-form'>
                  <label htmlFor="capacite">Capacité de communication</label>
                  <textarea
                    type="text"
                    id="capacite"
                    name="capacite"
                    className="input-textarea"
                    value={userCompetence.capacite}
                    onChange={handleCompetenceChange}
                  />
                </div>
              {/* </div> */}
              <div className='flex justify-end items-end mt-6'>
                <button className='btn-black' onClick={()=>handleInfoSubmit('competence')}>Modifier</button>
              </div>
            </div>

            {/* Formulaire d'ajout d'importation de diplôme ou certificat */}
            <div id='diplomas'>    <Diplome></Diplome></div>
        

            {/* Formulaire de modification du mot de passe */}
            <div className='border-line p-4 mb-4' id="password">
              <h5 className='text-lg mb-4 text-start'>Modifier le mot de passe</h5>
              <div className='flex justify-end items-end mt-6'>
                <button className='btn-black' onClick={()=>setIsPasswordModalOpen(true)}>Modifier</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modals 
        isOpen={isModalOpen}
        onRequestClose={cancelModification}>
            <div className="sm:flex sm:items-start">
                <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium" id="modal-headline">
                        Confirmation
                    </h3>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Voulez-vous enregistrer vos modifications ?
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                // onClick={handleSubmit}
                onClick={confirmModification}
                type="button" data-behavior="commit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Enregister
                </button>
                <button
                onClick={cancelModification}
                type="button" data-behavior="cancel" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-50 sm:mt-0 sm:w-auto sm:text-sm">
                    Annuler
                </button>
            </div>
            </Modals>
            <PasswordModal isOpen={isPasswordModalOpen} onClose={()=>setIsPasswordModalOpen(false)} />
            <Notification type="success" message="Inscription reussi." isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
    </>
  );
};

export default Parametre;
