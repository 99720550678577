import React from "react";
import Input from '../../../components/form/Input'
import { useOutletContext } from "react-router-dom";

export default function Programme() {

    const { data, setData } = useOutletContext()

    return (
        <div className="w-full flex flex-col justify-start items-start gap-y-4">
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="title w-full text-left">Formations Germe</span>
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Séminaire de Formation d’entrepreneurs
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.a1} onChange={(e) => { setData({ ...data, a1: e.target.value }) }} />
                    <Input type="date" title={"Date de participation"} value={data.a2} onChange={(e) => { setData({ ...data, a2: e.target.value }) }} />
                    <Input type="number" title={"Montant payé par l’entrepreneur"} value={data.a3} onChange={(e) => { setData({ ...data, a3: e.target.value }) }} />
                </div>
            </div>



            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Séminaire de recyclage
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.c1} onChange={(e) => { setData({ ...data, c1: e.target.value }) }} type="number" />
                    <Input type="date" title={"Date de participation"} value={data.c2} onChange={(e) => { setData({ ...data, c2: e.target.value }) }} />
                    <Input title={"Montant payé par l’entrepreneur"} value={data.c3} onChange={(e) => { setData({ ...data, c3: e.target.value }) }} type="number" />

                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session de groupe d’amélioration des entreprises (1)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.d1} onChange={(e) => { setData({ ...data, d1: e.target.value }) }} />
                    <Input type="date" title={"Date de participation"} value={data.d2} onChange={(e) => { setData({ ...data, d2: e.target.value }) }} />
                    <Input type="number" title={"Montant payé par l’entrepreneur"} value={data.d3} onChange={(e) => { setData({ ...data, d3: e.target.value }) }} />

                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session de groupe d’amélioration des entreprises (2)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.e1} onChange={(e) => { setData({ ...data, e1: e.target.value }) }} />
                    <Input type="date" title={"Date de participation"} value={data.e2} onChange={(e) => { setData({ ...data, e2: e.target.value }) }} />
                    <Input type="number" title={"Montant payé par l’entrepreneur"} value={data.e3} onChange={(e) => { setData({ ...data, e3: e.target.value }) }} />

                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session de groupe d’amélioration des entreprises (3)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">

                    <Input type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.f1} onChange={(e) => { setData({ ...data, f1: e.target.value }) }} />
                    <Input type="date" title={"Date de participation"} value={data.f2} onChange={(e) => { setData({ ...data, f2: e.target.value }) }} />
                    <Input type="number" title={"Montant payé par l’entrepreneur"} value={data.f3} onChange={(e) => { setData({ ...data, f3: e.target.value }) }} />

                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session d’encadrement Individuel (1)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">
                    <Input type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.g1} onChange={(e) => { setData({ ...data, g1: e.target.value }) }} />
                    <Input type="date" title={"Date de participation"} value={data.g2} onChange={(e) => { setData({ ...data, g2: e.target.value }) }} />
                    <Input type="number" title={"Montant payé par l’entrepreneur"} value={data.g3} onChange={(e) => { setData({ ...data, g3: e.target.value }) }} />
                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session d’encadrement Individuel (2)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full" >
                    <Input type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.h1} onChange={(e) => { setData({ ...data, h1: e.target.value }) }} />
                    <Input type="date" title={"Date de participation"} value={data.h2} onChange={(e) => { setData({ ...data, h2: e.target.value }) }} />
                    <Input type="number" title={"Montant payé par l’entrepreneur"} value={data.h3} onChange={(e) => { setData({ ...data, h3: e.target.value }) }} />
                </div>
            </div>
            <div className="flex flex-col justify-start  items-start gap-y-1">
                <span className="text-[18px] font-bold text-[#2C2F24]">
                    Session d’encadrement Individuel (3)
                </span>
                <div className="grid grid-cols-3 justify-start items-end gap-3 w-full">
                    <Input type="number" title={"Volonté de payer sa part avant la formation (montant en AR)"} value={data.j1} onChange={(e) => { setData({ ...data, j1: e.target.value }) }} />
                    <Input type="date" title={"Date de participation"} value={data.j2} onChange={(e) => { setData({ ...data, j2: e.target.value }) }} />
                    <Input type="number" title={"Montant payé par l’entrepreneur"} value={data.j3} onChange={(e) => { setData({ ...data, j3: e.target.value }) }} />
                </div>
            </div>

        </div>
    )
}