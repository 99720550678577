const selectInducateur =
    [
        {
            label: ' Non sensibilisé(e) et sans compétence technique',
            value: 0
        },
        {
            label: ' peu sensibilisé(e) et applications minimes des connaissances',
            value: 1
        },
        {
            label: ' sensibilisé(e) et quelques applications des connaissances',
            value: 2
        },
        {
            label: ' compétent(e)',
            value: 3
        },

    ]
const selectManuel = [
    {
        label: 'Besoin non satisfait',
        value: 0
    },
    {
        label: 'Besoin partiellement satisfait',
        value: 1
    },
    {
        label: 'Besoin satisfait',
        value: 2
    },
    {
        label: 'Pas de besoin dans le domaine',
        value: "x"
    },
]
export {
    selectInducateur, selectManuel
}