import React from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

export default function Back() {
    const navigate=useNavigate()
    return (
        <>
            <button onClick={()=>{navigate(-1)}}>
                <IoArrowBack />
            </button>
        </>
    )
}