import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
export default function Indicateur3() {
const {data,setData}=useOutletContext()
    return (
        <>
            <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title text-left w-full">Approvisionnement</span>
                <Select title={"Collecte les informations et les prix chez les fournisseurs"} values={selectInducateur} onChange={(e)=>{setData({...data,i31:e.target.value})}}/>
                <Select title={"Vérifie les marchandises à la livraison et traite bien les marchandises défectueuses"}  values={selectInducateur} onChange={(e)=>{setData({...data,i32:e.target.value})}}/>
                <Select title={"Planifie les approvisionnements selon les besoins de l’entreprise"}  values={selectInducateur} onChange={(e)=>{setData({...data,i33:e.target.value})}}/>
            </div>
        </>
    )

}