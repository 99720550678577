import React from 'react';
import Select from '../../Select'
import { selectManuel } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
import Input from '../../../../components/form/Input';
export default function Domaine() {
const {data,setData}=useOutletContext()
    return (
        <>
            <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
            <span className="title w-full text-left">Domaine</span>
                <Select title={"Informations sur le marché"} values={selectManuel} onChange={(e)=>{setData({...data,infoMarche:e.target.value})}}/>
                <Select title={"Registre du commerce"} values={selectManuel} onChange={(e)=>{setData({...data,registreCommerce:e.target.value})}}/>
                <Select title={"Assistance juridique"} values={selectManuel} onChange={(e)=>{setData({...data,AssistanceJuridique:e.target.value})}}/>
                <Select title={"Informations sur la technologie"} values={selectManuel} onChange={(e)=>{setData({...data,infoTechno:e.target.value})}}/>
                <Select title={"Formation technique"} values={selectManuel} onChange={(e)=>{setData({...data,formationTech:e.target.value})}}/>
                <Select title={"Assurances"} values={selectManuel} onChange={(e)=>{setData({...data,assurance:e.target.value})}}/>
                <Select title={"Crédits"} values={selectManuel} onChange={(e)=>{setData({...data,credit:e.target.value})}}/>
                <Select title={"Devises étrangères"} values={selectManuel} onChange={(e)=>{setData({...data,devises:e.target.value})}}/>
                <Select title={"Matières premières"} values={selectManuel} onChange={(e)=>{setData({...data,mp:e.target.value})}}/>
                <Select title={"Associations/Groupements"} values={selectManuel} onChange={(e)=>{setData({...data,Associations:e.target.value})}}/>
                <Input title={"Autre a preciser"} value={data.autre} onChange={(e)=>{setData({...data,autre:e.target.value})}}/>
                <Input title={"Autre a preciser"} value={data.autre2} onChange={(e)=>{setData({...data,autre2:e.target.value})}}/>

                {/* </div> */}
            </div>
        </>
    )

}