import React, { useEffect, useState } from 'react'
import Input from '../../entrepreneur/components/form/Input'
import { Button } from '@mui/material'
import Load from '../../entrepreneur/components/load'
import Notification from '../../entrepreneur/components/feedback/Notification'
import axios from 'axios'
import { api } from '../../api'
import { Code } from '../../script/generator'
import { useNavigate } from 'react-router-dom'

export default function ChagePassword({ data, setData, setPage }) {
    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })
    const [isOpen, setIsOpen] = useState({
        fails: false,success:false
    })
    const navigate = useNavigate()

    function handleSubmit(e) {
        e.preventDefault()
        setOptions({ ...options, loading: true })
        // setPage(prev => prev += 1)
        // setData({ ...data, codeGenerated: Code() })
        // console.log()
        try {
            setData({ ...data, codeGenerated: Code() })
            const form = new FormData()
            form.append('email', data.email)
            form.append('password', data.password)
            form.append('password', data.confirmPassword)
            axios.post(api + '/api/route', form)
                .then(response => {
                    console.log(response.data)
                    setOptions({ ...options, loading: false })
                    // setPage(prev => prev += 1)
                    setIsOpen({...isOpen, success: true })
                    setTimeout(() => {
                        navigate('/Login')
                    }, 500);
                    
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setIsOpen({ ...isOpen, fails: true })
                    setOptions({ ...options, loading: false })
                });
        } catch (error) {
            console.error(error)
            setIsOpen({ ...isOpen, fails: true })
            setOptions({ ...options, loading: false })

        }

    }



    useEffect(() => {
        if (data.password !== '' && data.confirmPassword !== '' && data.password === data.confirmPassword) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    return (
        <form onSubmit={(e) => { handleSubmit(e) }} className='w-full flex flex-col gap-y-3'>
            <Input title={"Mot de passe"} required={true} type="password" value={data.password} onChange={(e) => { setData({ ...data, password: e.target.value }) }} />
            <Input wrong={data.password !== '' && data.codeGenerated !== '' && (data.password !== data.confirmPassword)} title={"Confirmation de mot de passe"} required={true} type="password" value={data.confirmPassword} onChange={(e) => { setData({ ...data, confirmPassword: e.target.value }) }} />
            <Button style={{backgroundColor:'#1e293b'}}  fullWidth type="submit" variant='contained' disabled={options.disabled}>
                <Load title={'Changer'} loading={options.loading} />
            </Button>
            <Notification isOpen={isOpen.fails} type='error' message="Une erreur s'est produit." onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
        </form>
    )
}
