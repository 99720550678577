import React from 'react'
import { Link } from 'react-router-dom'
import {NavbarLinks} from '../../datas/Datas'
import Logo from "../../assets/icons/logo.png"
 

const ResponsiveSidebar = ({ showMenu, setShowMenu }) => {
  return (
    <div 
    onClick={() => setShowMenu(false)}
    className={`${showMenu ? "w-screen" : "w-0"} lg:hidden bg-gray-900/20 h-screen fixed z-20 top-0 backdrop-blur-md backdrop-filter`}
  >
    <div
      onClick={(e) => e.stopPropagation()} 
      className={`${
        showMenu ? "left-0 w-[300px] block" : "-left-[400px]"
      } fixed bottom-0 top-0 z-40 flex h-screen  flex-col justify-between bg-white  px-5 pb-6 pt-10 text-black transition-all duration-300 lg:hidden rounded-r-xl shadow-md`}
    >
       <div className=' mx-4 border-b-2 border-black-3/10 py-6 flex items-center '>
          
          <img src={Logo} alt="" className="h-20 w-20 mx-auto" />
          </div>
      <div className="">

        <nav className="mt-12">
          <ul className="space-y-4 text-xl text-start">
            {NavbarLinks.map((data) => (
              <li key={data.name}>
                <Link
                  to={data.link} 
                  onClick={() => setShowMenu(false)}
                  className="mb-5 inline-block text-black-900 text-base"
                >
                  {data.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <hr />
      <div className="footer font-thin text-xs">
        <p>
          @ Copyrighting Haisoa Consulting
        </p>
      </div>
    </div>
  </div>
  )
}

export default ResponsiveSidebar