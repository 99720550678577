import home2 from '../assets/SidebarIcon/disable/home-1-svgrepo-com.svg'
import formation2 from '../assets/SidebarIcon/disable/pinpaper-filled-svgrepo-com.svg'
import suitecase2 from '../assets/SidebarIcon/disable/suitcase-svgrepo-com.svg'
import users2 from '../assets/SidebarIcon/disable/users-svgrepo-com.svg'
import parameter2 from '../assets/SidebarIcon/disable/settings-svgrepo-com.svg'

import home1 from '../assets/icons/home-1-svgrepo-com.svg'
import formation from '../assets/icons/pinpaper-filled-svgrepo-com.svg'

// import home1 from '../assets/icons/home-svgrepo-com1.svg'
import parameter from '../assets/icons/settings-svgrepo-com.svg'
import users from '../assets/icons/users-svgrepo-com.svg'
import suitecase from '../assets/icons/suitcase-svgrepo-com.svg'


export const NavbarLinks = [
    {
      icon:home1,
      icon2:home2,
      name: "Tableau de bord",
      link: "/formateur/",
    },
    {
      icon:formation,
      icon2:formation2,
      name: "Inscription",
      link: "/formateur/inscription",
    },
    {
      icon:users,
      icon2:users2,
      name: "Entrepreneur",
      link: "/formateur/entrepreneur",
    },
    {
      icon:suitecase,
      icon2:suitecase2,
      name: "Formation",
      link: "/formateur/formation",
    },,
    {
      icon:parameter,
      icon2:parameter2,
      name: "Paramètre",
      link: "/formateur/parametre",
    }
  ];