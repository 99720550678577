import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PasswordModal = ({ isOpen, onClose }) => {
    const user_id = localStorage.getItem('naturagerme-id')

  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [confirmPasswordBorder, setConfirmPasswordBorder] = useState('border-gray-300');

  // Fonction pour gérer les changements dans les champs de mot de passe
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });

    // Comparaison en temps réel des mots de passe
    if (name === 'confirmPassword' || name === 'newPassword') {
      if (passwords.newPassword !== value) {
        setConfirmPasswordBorder('border-red-500');
      } else {
        setConfirmPasswordBorder('border-green-500');
      }
    }
  };

  const handlePasswordSubmit = () => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      alert("Les nouveaux mots de passe ne correspondent pas.");
      return;
    }

    axios.post(`/api/changerMdp/${user_id}`, passwords)
      .then(response => {
        console.log("Modification avec succès")
        onClose()
      })
      .catch(error => {
        console.error(error);
        onClose()
      });

    console.log('Mot de passe modifié:', passwords);
    onClose(); // Fermer le modal après la soumission
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black-1/20">
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="bg-white rounded-lg shadow-lg p-6 transform transition-transform duration-300 ease-in-out scale-95 w-[500px]">
        <h5 className='text-lg mb-4 text-start'>Modifier le mot de passe</h5>
        <div className='input-form mb-4'>
          <label htmlFor="oldPassword">Ancien mot de passe</label>
          <input
            type="password"
            id="oldPassword"
            name="oldPassword"
            className="input"
            value={passwords.oldPassword}
            onChange={handlePasswordChange}
          />
        </div>
        <div className='input-form mb-4'>
          <label htmlFor="newPassword">Nouveau mot de passe</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            className="input"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
          />
        </div>
        <div className={`input-form mb-6 ${confirmPasswordBorder}`}>
          <label htmlFor="confirmPassword">Confirmation de mot de passe</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            className={`input border-2 ${confirmPasswordBorder}`}
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
          />
        </div>
        <div className='flex justify-end items-end'>
        <button className='p-6 py-2' onClick={onClose}>Annuler</button>
          <button className='btn-black' onClick={handlePasswordSubmit}>Modifier</button>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
