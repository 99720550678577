import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Notification({ onClose, isOpen = false, type = "success", message = "Here is your message" }) {


  return (
    <div>
      {/* <Button onClick={handleClick}>Open Snackbar</Button> */}
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={onClose} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
        <Alert
          onClose={onClose}
          severity={type}

          variant="filled"
          sx={{ width: '100%' }}
          // key={"bottom" + "right"}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}