
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,

} from "@react-pdf/renderer"
import { useParams } from 'react-router-dom';
import { api } from '../../../api';


// Define styles for the PDF
const styles = StyleSheet.create({
  firstpage: {
    flexDirection: 'column',
    justifyContent: 'center', // Centre verticalement
    alignItems: 'center', // Centre horizontalement
    padding: 20,
    paddingTop: 300,
    height: '100%', 
  },
  sec1:{
    paddingHorizontal: 40,
  },

  firstsection: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
section: {
  margin: 10,
  padding: 10,
  flexGrow: 1,
},
firstTitle: {
  fontSize: 18,
  textAlign: 'center',
  fontWeight: 'bold',
  paddingHorizontal:50,
  marginBottom: 100,
},
text: {
  fontSize: 12,
  textAlign: 'center',
  marginBottom: 5,
},
  page: {
    padding: 30,
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 12,
    marginBottom: 6,
  },
  table: {
    display: 'table',
    width: 'auto',
    marginTop: 10,
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '20%',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#e0e0e0',
    padding: 5,
    fontWeight: 'bold',
  },
  tableCol: {
    width: '20%',
    borderWidth: 1,
    borderColor: '#000',
    padding: 5,
  },
  tableCellHeader: {
    fontWeight: 'bold',
    fontSize: 12,
  },
  tableCell: {
    fontSize: 12,
  },
});

const lists = ["Entreprise et famille", "Marketing", "Approvisionnement", "Gestion des stocks", "Coûts", "Tenue des registres", "Planification financière", "Personnel et prodductivité"]
function list_desire(item1, item2, item3, list) {
  if (item1 >= 0 && item2 > 0 && item3 > 0) {
    return list
  } else {
    return ""
  }
}
let count = {
  feminin: 0,
  masculin: 0,
  secondary: 0,
  baccalaureat: 0,
  superieur: 0,

}
function init_gender_count() {
  count.feminin = 0
  count.masculin = 0
  count.secondary = 0
  count.baccalaureat = 0
  count.superieur = 0
}
function genderCount(item) {
  if (item === "Masculin") {
    count.masculin += 1
  }
  if (item === "Feminin") {
    count.feminin += 1
  }
  console.log(item)
}
function levelCount(item){
  return ""
}

// PDF document component
const MyDocument = ({ data, formData, ageGroups, cleNotation, experienceInterval }) => (

  <Document>
        <Page style={styles.firstpage}>
      <View style={styles.firstsection}>
        <Text style={styles.firstTitle}>RAPPORT SUR L’ANALYSE DES BESOINS EN FORMATION OU ABF</Text>
        <View style={styles.sec1}>
          <Text style={styles.text}>Date : 15 et 16 Juillet</Text>
          <Text style={styles.text}>Lieu : Antsirabe</Text>
          <Text style={styles.text}>Objet : Analyse de besoins en formation GERME</Text>
          <Text style={styles.text}>
            Missionnaires : Les formateurs GERME 
            {/* - {data.formateur?.nom} */}
            {data.map((item, index) => {
               return (item.user?.liste_formateurs?.userId)
            })}
          </Text>
        </View>

      </View>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={{marginBottom:20}}>
        <Text style={styles.heading}>I. INTRODUCTION</Text>
        <Text style={styles.text}>{formData.introduction}</Text>
      </View>
      
      <View style={{marginBottom:20}}>
        <Text style={styles.heading}>II. Objectifs de l’analyse des besoins en formation (ABF)</Text>
        <Text style={styles.text}>{formData.objectif}</Text>
      </View>

      <View style={{marginBottom:20}}>
        <Text style={styles.heading}>III. Résultats attendus de la mission d’analyse des besoins en formation (ABF)</Text>
        <Text style={styles.text}>{formData.resultat}</Text>
      </View>

      <View style={{marginBottom:20}}>
        <Text style={styles.heading}>IV. Déroulement de d’analyse des besoins en formation (ABF)</Text>
        {/* <Text>a) Collecte des données</Text>
        <Text style={styles.text}>{formData.recapitulatif}</Text>
        <Text>b) Analyse des données</Text>
        <Text style={styles.text}>{formData.recapitulatif}</Text> */}
      </View>

      <View style={{marginBottom:20}}>
        <Text style={styles.heading}>VI. Récapitulatif des résultats de l’ABF Fournisseurs de NATURA MADAGASCAR</Text>
        <Text style={styles.text}>{formData.recapitulatif}</Text>
      </View>

      {/* Table for Entrepreneurs */}
      <View style={styles.table}>
        <Text style={styles.heading}>Les {data?.length} entrepreneur(s) entretenus</Text>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>N°</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Nom et prénoms</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Sexe</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Activité</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Localité</Text></View>
        </View>
        {data.map((item, index) => (

          <View style={styles.tableRow} key={index}>
            {

              genderCount(item?.user?.donnee_perso1?.sexe)

            }
            <View style={styles.tableCol}><Text style={styles.tableCell}>{index + 1}</Text></View>

            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.user.nom}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.user.donnee_perso1.sexe}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.user?.donnee_entreprise?.activiteEntreprise}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{item.user?.donnee_perso1?.adressePostale}</Text></View>

          </View>
        ))}
      </View>

      {/* Table for Gender Distribution */}
      <View style={styles.table}>
        <Text style={styles.heading}>Tableau des sexes des entrepreneurs</Text>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>FEMININ</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>MASCULIN</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>TOTAL</Text></View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.filter((item)=>item.user.donnee_perso1.sexe === "Féminin")?.length || "0"}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data.filter((item)=> item.user.donnee_perso1.sexe === "Masculin")?.length || "0"}</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>{data?.length}</Text>
          </View>
        </View>
      </View>

      {/* Table for Age Groups */}
      <View style={styles.table}>
        <Text style={styles.heading}>Tableau des tranches d’âge</Text>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Tranche d’âge</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Effectif</Text></View>
        </View>
        {/* {data.ageGroups.map((ageGroup, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>25-30 ans</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ageGroup.count}</Text></View>
          </View>
          
        ))}
        <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>31-40 ans</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ageGroup.count}</Text></View>
        </View>
        <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>41-50 ans</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ageGroup.count}</Text></View>
        </View>
        <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>51-60 ans</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ageGroup.count}</Text></View>
        </View>
        <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>61-70 ans</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{ageGroup.count}</Text></View>
        </View> */}

{Object.keys(ageGroups).map((ageGroup, index) => (
        <View style={styles.tableRow} key={index}>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{ageGroup} ans</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{ageGroups[ageGroup]}</Text></View>
        </View>
      ))}
       <View style={styles.tableRow}>
          <View style={styles.tableCol}><Text style={styles.tableCell}>Total</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{data.length}</Text></View>
        </View>
        
      </View>

      {/* Table for Education Level */}
      <View style={styles.table}>
        <Text style={styles.heading}>Tableau du niveau d’étude</Text>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Secondaire</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Baccalauréat</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Supérieur</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Total</Text></View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{data.filter((item)=>item.user.donnee_perso1.niveauFormation === "Secondaire")?.length || "0"}</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{data.filter((item)=>item.user.donnee_perso1.niveauFormation === "Baccalauréat")?.length || "0"}</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{data.filter((item)=>item.user.donnee_perso1.niveauFormation === "Supérieur")?.length || "0"}</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{data?.length || "0"}</Text></View>
        </View>
      </View>

      {/* Table for Years of Experience */}
      <View style={styles.table}>
        <Text style={styles.heading}>Tableau des années d’expérience en affaires</Text>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Années d’expérience</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Effectif</Text></View>
        </View>
        {/* {data.experienceYears.map((experience, index) => ( */}
         

        {Object.entries(experienceInterval).map(([interval, count]) => (
          <View style={styles.tableRow} key={interval}>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{interval}</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{count}</Text></View>
          </View>
        ))}
        {/* ))} */}
      </View>

      {/* Key Rating Table */}
      <View style={styles.table}>
        <Text style={styles.heading}>Clé de notation</Text>
        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>SFE</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>GAE</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>EI</Text></View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}><Text style={styles.tableCellHeader}>0-51</Text></View> 		
          <View style={styles.tableCol}><Text style={styles.tableCellHeader}>52-62</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCellHeader}>63-72</Text></View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{cleNotation.countSFE}</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{cleNotation.countGAE}</Text></View>
          <View style={styles.tableCol}><Text style={styles.tableCell}>{cleNotation.countEI}</Text></View>
        </View>
      </View>


      <View style={styles.table}>
        <Text style={styles.heading}>Les besoins en formation</Text>

        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>N°</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Nom et prénoms</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Domaine d'activité</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Horaire</Text></View>
          <View style={styles.tableColHeader}><Text style={styles.tableCellHeader}>Besoin en formation</Text></View>
        </View>

        {data.map((entrepreneur, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{index + 1}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{entrepreneur.name}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{entrepreneur.activity}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{entrepreneur.activity}</Text></View>
            <View style={styles.tableCol}><Text style={styles.tableCell}>{entrepreneur.formationneeded}</Text></View>
          </View>
        ))}

      </View>

      <View style={{marginBottom:20}}>
        <Text style={styles.heading}>VII. Recommandations</Text>
        <Text style={styles.text}>{formData.recommandation}</Text>
      </View>

      <View style={{marginBottom:20}}>
        <Text style={styles.heading}>IV. Conclusion</Text>
        <Text style={styles.text}>{formData.conclusion}</Text>
      </View>
    </Page>
  </Document>
)

const calculateAgeGroups = (data) => {
  const ageGroups = {
    '25-30': 0,
    '31-40': 0,
    '41-50': 0,
    '51-60': 0,
    '61-70': 0
  };

  const currentYear = new Date().getFullYear();

  data.forEach(item => {
    const birthYear = new Date(item.user.donnee_perso1.dateNaissance).getFullYear();
    const age = currentYear - birthYear;

    if (age >= 25 && age <= 30) {
      ageGroups['25-30']++;
    } else if (age >= 31 && age <= 40) {
      ageGroups['31-40']++;
    } else if (age >= 41 && age <= 50) {
      ageGroups['41-50']++;
    } else if (age >= 51 && age <= 60) {
      ageGroups['51-60']++;
    } else if (age >= 61 && age <= 70) {
      ageGroups['61-70']++;
    }
  })

  return ageGroups;
}

const calculateExperienceIntervals = ({data}) => {
  const counts = {
    '1-5 ans': 0,
    '6-10 ans': 0,
    '11-15 ans': 0,
    '16-20 ans': 0,
    '21-30 ans': 0,
    '31-40 ans': 0,
  };

  data.forEach((item) => {
    const experience =
      item?.user?.donnee_perso2?.anneeExperience; // Accéder à l'année d'expérience

    // Vérifier et incrémenter l'intervalle correspondant
    if (experience >= 1 && experience <= 5) {
      counts['1-5 ans'] += 1;
    } else if (experience >= 6 && experience <= 10) {
      counts['6-10 ans'] += 1;
    } else if (experience >= 11 && experience <= 15) {
      counts['11-15 ans'] += 1;
    } else if (experience >= 16 && experience <= 20) {
      counts['16-20 ans'] += 1;
    } else if (experience >= 21 && experience <= 30) {
      counts['21-30 ans'] += 1;
    } else if (experience >= 31 && experience <= 40) {
      counts['31-40 ans'] += 1;
    }
  });

  return counts;
};


const calculateTotalPoints = (abf1) => {
  if (!abf1) return 0;

  return (
    abf1.entrepriseFamille1 +
    abf1.entrepriseFamille2 +
    abf1.entrepriseFamille3 +
    abf1.marketing1 +
    abf1.marketing2 +
    abf1.marketing3 +
    abf1.approvisionnement1 +
    abf1.approvisionnement2 +
    abf1.approvisionnement3 +
    abf1.gestionStock1 +
    abf1.gestionStock2 +
    abf1.gestionStock3 +
    abf1.cout1 +
    abf1.cout2 +
    abf1.cout3 +
    abf1.tenueRegistre1 +
    abf1.tenueRegistre2 +
    abf1.tenueRegistre3 +
    abf1.planificationFin1 +
    abf1.planificationFin2 +
    abf1.planificationFin3 +
    abf1.persoProd1 +
    abf1.persoProd2 +
    abf1.persoProd3
  );
};

// Fonction pour classifier et compter les catégories SFE, GAE, EI
const classifyCategories = (data) => {
  let countSFE = 0;
  let countGAE = 0;
  let countEI = 0;

  data.forEach((item) => {
    const totalPoints = calculateTotalPoints(item.abf1);

    if (totalPoints >= 0 && totalPoints <= 51) {
      countSFE++;
    } else if (totalPoints >= 52 && totalPoints <= 62) {
      countGAE++;
    } else if (totalPoints >= 63 && totalPoints <= 72) {
      countEI++;
    }
  });

  return { countSFE, countGAE, countEI };
}



// Main component
const Rapport = () => {
  const [data, setData] = useState({
    'introduction': "",
     'objectif':"", 
     'resultat': "", 
     'recapitulatif': "", 
     'recommandation': "", 
     'conclusion': ""
  })
  const [allInfo, setAllInfo] = useState([])
  const [training, setTraining] = useState([])
  const [ageGroups, setAgeGroups] = useState({})
  const [cleNotation, setCleNotation] = useState({
     "countSFE" : 0,
     "countGAE" : 0,
     "countEI" : 0
  })
  const [intervalCounts, setIntervalCounts] = useState({
    '1-5 ans': 0,
    '6-10 ans': 0,
    '11-15 ans': 0,
    '16-20 ans': 0,
    '21-30 ans': 0,
    '31-40 ans': 0,
  });
  // const [besoins_formation,set_besoins_formation]=useState([])

  useEffect(() => {


    // api pour liste de besoin de formation
    const form = new FormData()
      form.append("formation_id", 2)

    axios.post(api+'/api/getRapportABS', form)
    .then((response) => {
      // setData(response.data);
      
      setAllInfo(response.data)
      console.log("Rapport ABF ",response.data)
      const groups = calculateAgeGroups(response.data)
      setAgeGroups(groups);
      setIntervalCounts(calculateExperienceIntervals (response.data))
      console.log("Age Group", groups)

      setCleNotation(classifyCategories(response.data))
      console.log("Clé de notation", cleNotation)
    })
    .catch((error) => {
      console.error('Error fetching data: ', error);
    })


  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <>
      {/* <Header title="Rapport" /> */}
      <div className='border-line p-6 mb-4 mr-6'>
        <div className='flex w-full justify-between items-center mb-6'>
          <h1 className='text-xl font-semibold'>Information générale sur le rapport d'un ABF</h1>
          <PDFDownloadLink
            document={<MyDocument formData={data} data={allInfo} ageGroups={ageGroups} cleNotation={cleNotation} experienceInterval={intervalCounts}/>}
            fileName="rapport.pdf"
            className="btn-black"
          >
            {({ loading }) => (loading ? 'Chargement...' : 'Imprimer en PDF')}
          </PDFDownloadLink>
        </div>

        <div className=''>
          {['introduction', 'objectif', 'resultat', 'recapitulatif', 'recommandation', 'conclusion'].map((field, index) => (
            <div key={index} className='input-form'>
              <label htmlFor={field} className="block text-sm font-medium text-gray-700 text-start">
                {field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' ')}
              </label>
              <textarea
                id={field}
                name={field}

                className="input-textarea h-[200px]"

                placeholder={`Ajouter ${field}`}
                value={data[field]}
                onChange={handleChange}
              />
            </div>
          ))}
        </div>

       </div>
     </>

  );
};

export default Rapport;





