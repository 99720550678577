import React, { useEffect, useState } from 'react';
import img from '../assets/Logo.png'
import Input from '../entrepreneur/components/form/Input';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api } from '../api';
import Notification from '../entrepreneur/components/feedback/Notification';
import Load from '../entrepreneur/components/load';
// import Button from '../entrepreneur/components/btn/Button';
export default function Login() {
    const navigate = useNavigate()

    const [data, setData] = useState({
        email: '',
        password: '',
    })
    const [options, setOptions] = useState(
        {
            disabled: true,
            loading: false
        }
    )
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false
    })

    async function login() {
        setOptions({ ...options, loading: true })
        try {
            const form = new FormData()
            form.append("email", data.email)
            form.append("password", data.password)
            await axios.post(api + "/api/connexion", form)
                .then(res => {
                    console.log(res.data)
                    setIsOpen({ ...isOpen, success: true })
                    setOptions({ ...options, loading: false })
                    localStorage.setItem("naturagerme-id", res.data.user.id)
                    localStorage.setItem("naturagerme-email", res.data.user.email)
                    localStorage.setItem("naturagerme-type", res.data.role)
                    localStorage.setItem("naturagerme-photo", res.data.user.photo)
                    localStorage.setItem("naturagerme-name", res.data.user.id)

                    if (res.data.role === "Entrepreneur") {
                        navigate('/')
                    }
                    if (res.data.role === "Formateur") {
                        navigate('/formateur/')
                    }
                })
                .catch(err => {
                    console.error(err.data)
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, fails: true })
                })
        } catch (error) {
            console.error(error);
            setIsOpen({ ...isOpen, fails: true })
            setOptions({ ...options, loading: false })

        }
    }

    useEffect(() => {
        if (data.email !== '' && data.password !== '') {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])


    return (
        <div className='flex flex-row max-lg:justify-center lg:justify-between items-center '>
            <div className="w-3/12 h-screen bg-slate-50 flex flex-col justify-center items-center max-lg:hidden">
                <div className="flex flex-col gap-x-3 items-center gap-y-12">
                    <div className='w-9/12 bg-white shadow-md shadow-slate-500/20 rounded-[4px]'>
                        <img src={img} alt='logo' className="w-[300px]" />
                    </div>
                </div>
            </div>
            <div className='max-lg:w-11/12 lg:w-9/12 h-screen bg-white lg:shadow-md lg:shadow-slate-500/40 flex flex-row justify-center items-center'>

                <form onSubmit={(e) => { e.preventDefault(); login() }} className='max-lg:w-full lg:w-5/12 flex flex-col gap-y-3'>
                    <div className="w-full flex flex-row justify-center items-center lg:hidden">
                        <img src={img} alt='logo' className="w-[150px]" />
                    </div>
                    <h4 className='text-xl font-bold '>S'authentifier</h4>
                    <Input title={"E-mail"} placeholder={"example@gmail.com"} value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} type='email' />
                    <Input title={"Mot de passe"} value={data.password} onChange={(e) => { setData({ ...data, password: e.target.value }) }} type='password' />
                    <div className='w-full flex flex-row justify-end items-center '>
                        {/* <button type="button" onClick={()=>{navigate('/forgotpassword')}} className='text-blue-500'>Mot de passe oublier</button> */}
                        <button type="button"  className='text-blue-500'>Mot de passe oublier</button>

                    </div>
                    <Button

                        type="submit" disabled={options.disabled} sx={{
                            bgcolor: "#FF7D00",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#FF9933", // Change the hover background color
                                color: "white", // Change the hover text color
                            },
                        }} variant='contained' className="px-9 ">
                        <Load title="Se connecter" loading={options.loading} />
                    </Button>                    <div>
                        <span>Vous n'avez pas de compte ?  </span>
                        <button className='text-blue-400' onClick={() => { navigate('/Signup') }}>
                            S'inscrire
                        </button>

                    </div>
                </form>
            </div>
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
            <Notification type="success" message="Connecté avec succès." isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />

        </div>
    )
}