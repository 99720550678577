// import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import React from 'react';

export default function Button({ title, onClick, Logo = null,loading=false,disabled=false,type="solid" }) {
    return (
        <button type="button" onClick={onClick} className={`btn ${type=="outline"?"btn-outline":"btn-solid"} flex flex-row justify-center items-center gap-x-3 `}>
            {
                Logo != null && (
                    <Logo />
                )
            }
           {
            loading==true &&(
               <div className='animate-spin duration-300 text-gray-500'>
                 <faSpinner/>
               </div>
            )||loading===false&&(
                <span>{title}</span>
            )
           }
        </button>
    )
}