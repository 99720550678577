import { Button } from '@mui/material';

// import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Indicateur1 from './Indicateur/1'
import Indicateur2 from './Indicateur/2'

import Indicateur3 from './Indicateur/3'

import Indicateur4 from './Indicateur/4'

import Indicateur5 from './Indicateur/5'

import Indicateur6 from './Indicateur/6'

import Indicateur7 from './Indicateur/7'

import Indicateur8 from './Indicateur/8'
import Manuel from './Manuel/manuel'
import Domaine from './Manuel/domaine'
import Programme from './Programme';
import axios from 'axios';
import { api } from '../../../api';
import Load from '../../components/load';
import Notification from '../../components/feedback/Notification';
// import Button from '../../components/btn/Button';


export default function ABF() {
    const { id } = useParams()
    const { data, setData, activeStep, setActiveStep } = useOutletContext()
    const [page, setPage] = useState(1)
    const [options, setOptions] = useState({
        loading: false
    })
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false
    })
    const [pageManuel, setPageManuel] = useState(1)
    function register() {
        setOptions({ ...options, loading: true })
        try {
            const form = new FormData()
            form.append("entrepriseFamille1", data.i11)
            form.append("entrepriseFamille2", data.i12)
            form.append("entrepriseFamille3", data.i13)
            form.append("marketing1", data.i21)
            form.append("marketing2", data.i22)
            form.append("marketing3", data.i23)
            form.append("approvisionnement1", data.i31)
            form.append("approvisionnement2", data.i32)
            form.append("approvisionnement3", data.i33)
            form.append("gestionStock1", data.i41)
            form.append("gestionStock2", data.i42)
            form.append("gestionStock3", data.i43)
            form.append("cout1", data.i51)
            form.append("cout2", data.i52)
            form.append("cout3", data.i53)
            form.append("tenueRegistre1", data.i61)
            form.append("tenueRegistre2", data.i62)
            form.append("tenueRegistre3", data.i63)
            form.append("planificationFin1", data.i71)
            form.append("planificationFin2", data.i72)
            form.append("planificationFin3", data.i73)
            form.append("persoProd1", data.i81)
            form.append("persoProd2", data.i82)
            form.append("persoProd3", data.i83)
            form.append("manuelMarketing", data.Marketing)
            form.append("manuelApprov", data.Approvisionnement)
            form.append("manuelGestStock", data.gestionStocks)
            form.append("manuelCout", data.couts)
            form.append("manuelCompta", data.Comptabilité)

            form.append("manuelPlanFin", data.planuficationFinanciere)
            form.append("manuelPersoProd", data.personnel)
            form.append("infoMarche", data.infoMarche)
            form.append("registreCom", data.registreCommerce)
            form.append("assistanceJur", data.AssistanceJuridique)
            form.append("infoTechno", data.infoTechno)
            form.append("formationTech", data.formationTech)
            form.append("infoTechno", data.infoTechno)
            form.append("assurance", data.assurance)
            form.append("credit", data.credit)
            form.append("deviseEtrangere", data.devises)
            form.append("matierePremiere", data.mp)
            form.append("associationGroup", data.Associations)

            form.append("autre1", data.autre)
            form.append("autre2", data.autre2)
            form.append("form1Montant1", data.a1)
            form.append("form1Date", data.a2)
            form.append("form1Montant2", data.a3)
            // form.append("form1Pourcentage",data.i11)
            form.append("form2Montant1", data.b1)
            form.append("form2Date", data.b2)
            form.append("form2Montant2", data.b3)
            // form.append("form2Pourcentage",data.i11)
            form.append("form3Montant1", data.c1)
            form.append("form3Date", data.c2)
            form.append("form3Montant2", data.c3)
            // form.append("form3Pourcentage",data.i11)
            form.append("form4Montant1", data.d1)
            form.append("form4Date", data.d2)
            form.append("form4Montant2", data.d3)
            // form.append("form4Pourcentage",data.i11)
            form.append("form5Montant1", data.e1)
            form.append("form5Date", data.e2)

            form.append("form5Montant2", data.e3)
            // form.append("form5Pourcentage",data.i11)
            form.append("form6Montant1", data.f1)
            form.append("form6Date", data.f2)
            form.append("form6Montant2", data.f3)

            // form.append("form6Pourcentage",data.i11)
            form.append("form7Montant1", data.g1)
            form.append("form7Date", data.g2)
            form.append("form7Montant2", data.g3)
            // form.append("form7Pourcentage",data.i11)
            form.append("form8Montant1", data.h1)
            form.append("form8Date", data.h2)
            form.append("form8Montant2", data.h3)
            form.append("form8Pourcentage", data.i11)


            axios.post(api + '/api/inscriptionFormation/' + localStorage.getItem('naturagerme-id') + "/" + id, form)
                .then(res => {
                    console.log(res.data)
                    setOptions({ ...options, loading: false })
                   
                    setIsOpen({...isOpen, success: true })
                    setTimeout(() => {
                        navigate('/Formation')
                    }, 500);

                })
                .catch(err => {
                    console.error(err)
                    setOptions({ ...options, loading: false })
                    setIsOpen({...isOpen, fails: true })

                })
        } catch (error) {
            console.error(error)
            setOptions({ ...options, loading: false })
            setIsOpen({...isOpen, fails: true })

        }
    }


    useEffect(() => {
        if (page > 8) {
            setActiveStep(prev => prev += 1)
            setPage(1)
        }
    }, [page])
    useEffect(() => {
        if (pageManuel > 2) {
            setActiveStep(prev => prev += 1)
            setPageManuel(1)
        }
    }, [pageManuel])
    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <div className='w-full'>
            <div>
                {
                    activeStep == 0 && (
                        <>
                            {
                                page == 1 && (
                                    <Indicateur1 />
                                )
                            }
                            {
                                page == 2 && (
                                    <Indicateur2 />
                                )
                            }
                            {
                                page == 3 && (
                                    <Indicateur3 />
                                )
                            }
                            {
                                page == 4 && (
                                    <Indicateur4 />
                                )
                            }
                            {
                                page == 5 && (
                                    <Indicateur5 />
                                )
                            }
                            {
                                page == 6 && (
                                    <Indicateur6 />
                                )
                            }
                            {
                                page == 7 && (
                                    <Indicateur7 />
                                )
                            }
                            {
                                page == 8 && (
                                    <Indicateur8 />
                                )
                            }
                            <div className='w-full flex flex-row justify-end items-center'>
                                <Button variant='contained' style={{ backgroundColor: 'slategray' }} onClick={() => { setPage(prev => prev += 1) }}>
                                    Suivant
                                </Button>
                            </div>
                        </>
                    )
                }
                {
                    activeStep == 1 && (
                        <>
                            {
                                pageManuel == 1 && (
                                    <Manuel />
                                )
                            }
                            {
                                pageManuel == 2 && (
                                    <Domaine />
                                )
                            }
                            <div className='w-full mt-2 flex flex-row justify-end items-center'>
                                <Button variant='contained' style={{ backgroundColor: 'slategray' }} onClick={() => { setPageManuel(prev => prev += 1) }}>
                                    Suivant
                                </Button>
                            </div>
                        </>
                    )
                }
                {
                    activeStep == 2 && (
                        <>
                            <Programme />
                            <div className='w-full mt-2 flex flex-row justify-end items-center'>
                                <Button variant='contained' style={{ backgroundColor: 'slategray' }} onClick={() => { register() }}>
                                    <Load loading={options.loading} title={"Terminer"} />
                                </Button>
                            </div>
                        </>
                    )
                }

            </div>
            <Notification type="success" message="Inscription reussi." isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />

        </div>
    )
}