import React from 'react';

export default function About() {
  return (
    <div className="p-8 ">
      <h1 className="text-2xl font-bold text-gray-800">À propos</h1>
      <p className=" text-gray-600">Bienvenue sur notre site web !</p>
      <p className=" text-gray-600 mb-4">Notre mission est d’aider les entrepreneurs et les entreprises à développer les compétences et les connaissances nécessaires pour réussir.</p>
      <h2 className="text-xl font-bold text-gray-800 mb-2">Nos services</h2>
      <ul className="list-disc text-gray-600 mb-4">
        <li>Programmes de formation personnalisés</li>
        <li>Ressources d’apprentissage en ligne</li>
        <li>Ateliers et séminaires</li>
        <li>Conseil en carrière</li>
      </ul>
      <h2 className="text-xl font-bold text-gray-800 mb-2">Contactez-nous</h2>
      <p className=" text-gray-600 mb-1">Email : haisoa@gmail.com</p>
      <p className=" text-gray-600">Téléphone : (123) 456-7890</p>
    </div>
  );
}