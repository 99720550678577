import React, { useEffect, useState } from 'react';
import img from '../../../../assets/black.jpg'
import CardFormation from '../../../components/card_formation';
import Button from '../../../components/btn/Button';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../api';
import axios from 'axios';
export default function Formation() {
    const [data, setData] = useState([
        // {
        //     id: 1,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 2,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 3,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 4,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 5,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },
        // {
        //     id: 6,
        //     image: img,
        //     date: '12/12/2001',
        //     duree: '1h',
        //     description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur dicta, pariatur voluptas quo accusantium quibusdam?"
        // },

    ])
    const navigate = useNavigate();

    useEffect(() => {
        // Remplacer cette URL par l'URL de votre API Laravel
        try {
            axios.get(api + '/api/getFormationsAcc')
                .then(response => {
                    setData(response.data);
                    // console.log(response.data.length);
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des formations:", error);
                });
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <div className='w-screen bg-slate-50 flex flex-col  items-center'>
            {
                data.length > 0 && (
                    <div className='w-11/12 flex flex-row py-4 justify-between items-center '>
                        <span className='text-[#2C2F24] text-3xl  font-medium'>Formation</span>
                        <Button type='outline' title={"Voir tous"} onClick={() => { navigate('/Formation') }} />
                    </div>
                )
            }
            <div className='w-11/12 grid max-md:grid-cols-2 max-lg:grid-cols-3 lg:grid-cols-5 justify-start items-center gap-5'>

                {
                    data.length <= 10 && data.map((item, index) => (
                        <CardFormation item={item} key={index} />
                    ))
                }
            </div>
        </div>
    )
}