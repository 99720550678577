import React, { useState } from 'react';
import CustomStepper from '../../components/stepper/stepper';
import { Outlet } from 'react-router-dom';
import { Button } from '@mui/material';
import { IoArrowBack } from 'react-icons/io5';
// import Button from '../../components/btn/Button';

// create function user
export default function ABFLayout() {
    const [activeStep, setActiveStep] = useState(0)
    const [data, setData] = useState({
        i11: 0,
        i12: 0,
        i13: 0,
        i21: 0,
        i22: 0,
        i23: 0,
        i31: 0,
        i32: 0,
        i33: 0,
        i41: 0,
        i42: 0,
        i43: 0,
        i51: 0,
        i52: 0,
        i53: 0,
        i61: 0,
        i62: 0,
        i63: 0,
        i71: 0,
        i72: 0,
        i73: 0,
        i81: 0,
        i82: 0,
        i83: 0,

        Marketing: 0,
        Approvisionnement:0,
        gestionStocks: 0,
        couts: 0,
        Comptabilité:0,
        planuficationFinanciere:0,
        personnel: 0,
        infoMarche: 0,
        registreCommerce: 0,
        AssistanceJuridique: 0,
        infoTechno: 0,
        formationTech: 0,
        assurance: 0,
        credit: 0,
        devises: 0,
        mp: 0,
        Associations: 0,
        autre: 0,
        autre2: 0,

        a1: '',
        a2: '',
        a3: '',
        b1: '',
        b2: '',
        b3: '',
        c1: '',
        c2: '',
        c3: '',
        d1: '',
        d2: '',
        d3: '',
        e1: '',
        e2: '',
        e3: '',
        f1: '',
        f2: '',
        f3: '',
        g1: '',
        g2: '',
        g3: '',
        h1: '',
        h2: '',
        h3: '',


    })

    return (
        <div className='w-screen flex flex-col justify-start items-center py-7 bg-slate-50'>
            <span className='text-4xl font-semibold'>Analyse des besoin en formation</span>
            <div className='flex w-11/12 flex-row  justify-between items-start mt-8'>
                <div className='w-3/12'>
                    <CustomStepper activeStep={activeStep} />
                </div>
                <div className='w-8/12 bg-white card p-3 '>

                    {
                        activeStep > 0 && (
                            <Button onClick={() => { setActiveStep(prev => prev -= 1) }}>
                                <div className='flex flex-row justify-start items-center gap-x-3'>
                                    <IoArrowBack />
                                    <span>Retour</span>
                                </div>
                            </Button>
                        )
                    }
                    <Outlet context={{ activeStep, setActiveStep, data, setData }} />

                </div>
            </div>
        </div>
    )
}