import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
export default function Indicateur6() {
const {data,setData}=useOutletContext()
    return (
        <>
            <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title text-left w-full">Tenue des registres</span>
                <Select title={"Tient un journal clair et à jour"} values={selectInducateur} onChange={(e)=>{setData({...data,i61:e.target.value})}}/>
                <Select title={"Tient un compte client"} values={selectInducateur} onChange={(e)=>{setData({...data,i62:e.target.value})}}/>
                <Select title={"Calcule régulièrement les bénéfices et pertes"} values={selectInducateur} onChange={(e)=>{setData({...data,i63:e.target.value})}}/>
            </div>
        </>
    )

}
