import React, { useEffect, useState } from 'react';
// import Button from '../../entrepreneur/components/btn/Button';
import Input from '../../entrepreneur/components/form/Input';
import { Button } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { api } from '../../api';
import Load from '../../entrepreneur/components/load';
import Notification from '../../entrepreneur/components/feedback/Notification';

export default function Verification() {
    const { setStep, data } = useOutletContext()
    const [verifing, setVerifing] = useState(undefined)
    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
    })

    const navigate = useNavigate()


    useEffect(() => {
        console.log(data)
    }, [data])
    async function createAccount() {
        // setStep(prev => prev += 1)
        // setIsOpen({...isOpen,fails: true})
        console.log(data)
        setOptions({ ...options, loading: true })
        if (data.code === verifing) {
            try {
                const form = new FormData()
                form.append('nom', data.nom)
                form.append('email', data.email)
                form.append('password', data.password)
                form.append('photo', data.photo)
                form.append('code', data.code)
                form.append('role', data.type)
                await axios.post(api + '/api/inscriptionCompte', form)
                    .then(res => {
                        console.log(res.data)
                        localStorage.setItem('naturagerme-id', res.data.id)
                        if (data.type === 'Formateur') {
                            navigate('/Login')
                        } else {
                            setStep(prev => prev += 1)
                        }
                        setOptions({ ...options, loading: false })
                        setIsOpen({ ...isOpen, success: true })
                    })
                    .catch(err => {
                        console.error(err)
                        setIsOpen({ ...isOpen, fails: true })
                        setOptions({ ...options, loading: false })
                    })
            } catch (error) {
                console.error(error)
                setIsOpen({ ...isOpen, fails: true })
                setOptions({ ...options, loading: false })

            }
        }

    }

    useEffect(() => {
        if (verifing != undefined) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [verifing])

    return (
        <form onSubmit={(e) => { e.preventDefault(); createAccount() }} className="max-md:w-10/12 lg:w-7/12 flex flex-col gap-y-2">
            <span className="font-black text-3xl">
                Vérification de compte
            </span>
            <p>Nous avions envoyer un code de vérification a votre adresse e-mail </p>
            <div className="w-full flex flex-row justify-between items-center gap-x-3">
                <Input title={"Code de vérification"} type='number' onChange={(e) => {
                    try {
                        setVerifing(JSON.parse(e.target.value))
                    }

                    catch (error) {
                        console.log(error);
                        setVerifing(undefined);
                    }

                }}
                />
            </div>

            <Button
                type='submit'
                disabled={options.disabled} sx={{
                    bgcolor: "#FF7D00",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#FF9933", // Change the hover background color
                        color: "white", // Change the hover text color
                    },
                }} variant='contained' className="px-9 " >
                <Load title={"Verifier"} loading={options.loading} />                </Button>
            <Notification type="error" message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />

        </form>
    )
}