import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
export default function Indicateur5(){
const {data,setData}=useOutletContext()
    return (
        <>
        <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
        <span className="title text-left w-full">Coûts</span>
        <Select title={"Calcule les coûts directs des produits / services"} values={selectInducateur} onChange={(e)=>{setData({...data,i51:e.target.value})}}/>
        <Select title={"Calcule les coûts directs de la main-d’œuvre"} values={selectInducateur} onChange={(e)=>{setData({...data,i52:e.target.value})}}/>
        <Select title={"Calcule les coûts indirects"} values={selectInducateur} onChange={(e)=>{setData({...data,i53:e.target.value})}}/>
        </div>
        </>
    )

}