import React from 'react';
import Select from '../../Select'
import { selectInducateur } from '../../../../data';
import { useOutletContext } from 'react-router-dom';
export default function Indicateur8() {
const {data,setData}=useOutletContext()
    return (
        <>
            <div className='flex flex-col justify-start mb-2 items-center gap-y-2 w-full'>
                <span className="title text-left w-full">Personnel et Productivité</span>
                <Select title={"Maîtrise les facteurs et indicateurs de production"} values={selectInducateur} onChange={(e)=>{setData({...data,i81:e.target.value})}}/>
                <Select title={"Sait recruter les personnes productives"} values={selectInducateur} onChange={(e)=>{setData({...data,i82:e.target.value})}}/>
                <Select title={"Encourage la productivité du personnel"} values={selectInducateur} onChange={(e)=>{setData({...data,i83:e.target.value})}}/>
            </div>
        </>
    )

}