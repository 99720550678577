import React from 'react';
import { api } from '../../../api';
import { useNavigate } from 'react-router-dom';
export default function CardFormation({ item }) {
const navigate=useNavigate()
function go(){
   
    if( localStorage.getItem('naturagerme-id')){
        navigate('/ABF/'+item.id)
    } else {
        navigate('/Login')
    }
}
    return (
        <>
            <div onClick={()=>{
              go()
            }} className="flex flex-col bg-white rounded-[4px] shadow-md shadow-slate-300/50">
                <img src={api + '/storage/' + item.photo} alt='photo' />
                <div className='p-3 flex flex-col items-start'>
                    <h4 className='text-[#2C2F24] '>{item.titre}</h4>
                    <p className='text-[#2C2F24]'>{item.description}</p>
                    <div className="flex flex-row text-[#737865] font-medium text-[12px] justify-between w-full border-t items-center">
                        <span>{item.dateDebut}</span>
                        <span>{item.duree}</span>
                    </div>
                </div>
            </div>
        </>
    )
}