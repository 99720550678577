import React, { useEffect, useState } from 'react'
import Input from '../../entrepreneur/components/form/Input'
import { Button } from '@mui/material'
import Load from '../../entrepreneur/components/load'
import Notification from '../../entrepreneur/components/feedback/Notification'
import axios from 'axios'
import { api } from '../../api'
import { Code } from '../../script/generator'

export default function VerificationEmail({ data, setData, setPage }) {
    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })
    const [isOpen, setIsOpen] = useState({
        fails: false
    })

    function handleSubmit(e) {
        e.preventDefault()
        setOptions({ ...options, loading: true })
        // setPage(prev => prev += 1)
        let coder = Code()
        setData({ ...data, codeGenerated: coder })
        // console.log()
        try {
            setData({ ...data, codeGenerated: Code() })
            const form = new FormData()
            form.append('email', data.email)
            form.append('code', coder)
            axios.post(api + '/api/verificationEmail', form)
                .then(response => {
                    console.log(response.data)
                    setOptions({ ...options, loading: false })
                    setPage(prev => prev += 1)


                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setIsOpen({ ...isOpen, fails: true })
                    setOptions({ ...options, loading: false })
                });
        } catch (error) {
            console.error(error)
            setIsOpen({ ...isOpen, fails: true })
            setOptions({ ...options, loading: false })

        }

    }

    useEffect(() => {
        if (data.email !== '') {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    return (
        <form onSubmit={(e) => { handleSubmit(e) }} className='w-full flex flex-col gap-y-3'>
            <Input title={"Votre e-mail"} required={true} type="email" value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} />
            <Button fullWidth type="submit" style={{ backgroundColor: '#1e293b' }} variant='contained' disabled={options.disabled}>
                <Load  title={'Suivant'} loading={options.loading} />
            </Button>
            <Notification isOpen={isOpen.fails} type='error' message="Une erreur s'est produit." onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
        </form>
    )
}
